import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {MapDataType} from "../common/types";
import {AxiosError} from "axios";
import {mapApi} from "../api/mapApi";
import {mosc} from "../common/optionsForMaps";
import {getCoordRequest} from "../utils/calcCoord";
import {log} from "node:util";


export const fetchPolygonsTC = createAsyncThunk('fetchPolygons', async (_,  {dispatch, rejectWithValue}) => {
    try {
        const res = await mapApi.getPolygons()
        return res.data
    } catch(err) {
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }

        return rejectWithValue(error.response)
    }
})
export const fetchBuildingsTC = createAsyncThunk('fetchBuildings', async (lavka_title: string,  {dispatch, rejectWithValue}) => {
    try {
        const res = await mapApi.getBuildings(lavka_title)
        return res.data
    } catch(err) {
        const error = err as AxiosError
        if (!error.response) {
            throw err
        }

        return rejectWithValue(error.response)
    }
})

export const fetchStoreTC = createAsyncThunk('fetchStore', async (params: {coords_sw?: number[], coords_ne?: number[]} = {},  {dispatch, rejectWithValue}) => {
    try {
        const res =  await mapApi.getStore(params)
        return res.data
    } catch(err) {
        const error = err as AxiosError
        if (!error.response) {
            throw err
        }

        return rejectWithValue(error.response)
    }
})



export const slice = createSlice({
    name: 'map',
    initialState: {
        polygons: [],
        buildings: [],
        lavkas: [],
        currentPoint: {center: [55.75, 37.57], zoom: 11},
        activeZone: '',

        city: 'Москва',
        currentZoom: 11,
        currentBounds: mosc,

        search: ''
    } as MapDataType,
    reducers: {
        selectPointAC(state, action) {
            state.currentPoint = action.payload
        },
        selectActiveZoneAC(state, action) {
            state.activeZone = action.payload
        },
        selectCityAC(state, action) {
            state.city = action.payload
        },
        selectCurrentZoomAC(state, action) {
            state.currentZoom = action.payload
        },
        selectCurrentBoundsAC(state, action) {
            state.currentBounds = action.payload
        },
        searchAC(state, action) {
            state.search = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPolygonsTC.fulfilled, (state, action) => {
                state.polygons = action.payload
            })
            .addCase(fetchBuildingsTC.fulfilled, (state, action) => {
                state.buildings = action.payload
            })
            .addCase(fetchStoreTC.fulfilled, (state, action) => {
                state.lavkas = action.payload
            })
    }
})

export const mapReducer = slice.reducer
export const {
    selectPointAC,
    selectActiveZoneAC,
    selectCityAC,
    selectCurrentZoomAC,
    selectCurrentBoundsAC,
    searchAC
} = slice.actions