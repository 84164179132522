import * as React from 'react';
import {useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {
    changeDataFactor, dataForCharts,
    selectCategory,
    selectChart,
    selectDataApi,
    selectDataForCatalog,
    selectDataName, selectDataSale, selectDataSalePercent,
    selectNamesForCatalog,
    selectSelloffChecked,
    selectSwitchShares,
    selectSwitchSOV, selectTableChartCategories,
    selectTime,
    visiblePDF
} from "../../../../common/selectors";
import {setPoints, setPointsForDataLabels, weekAnnotations} from "../../../../common/optionsForCharts/weekAnnotations";
import {fillForColumns} from "../../../../common/optionsForCharts/addDifferentColorsForMarkets";
import {Slider} from "antd";
import styles from './marketChart.module.sass'
import {
    dataForGrowingMarket,
    offset,
    offsetXLabels,
    settingsColors,
    settingsColumnWidth,
    settingsFill,
    settingsPeriod,
    settingsPlotOptionsOrientation,
    settingsStrokeDashArray,
    settingsStrokeWidth,
    settingsToolBar,
    settingsYaxis
} from "../../../../common/optionsForCharts/visualSettings";
import {zoomAC} from "../../../../redux/optionsReducer";
import {
    formatArrayTime,
    formatDateForCategories,
    formatTime,
    getPeriod,
    periodLineChart,
    valueOf
} from "../../../../common/optionsForDate";
import {formatGrade} from "../../../../common/optionsForCategories/table";
import {ValuesCharts} from "../../../../utils/valuesCharts";
import {CATALOG_FACTOR, FONT_SIZE_DATA_LABELS, SAM_AKB_FACTOR, WB_AKB_FACTOR_MIN} from "../../../../common/constants";
import dayjs from "dayjs";
import {dataType} from "../../../../common/types";

type xAxisType = {
    type: "datetime" | "category" | "numeric" | undefined
}


export const ChartComponent = () => {
    const dataForCatalogOrigin = useAppSelector(selectDataForCatalog)
    const dataCharts = useAppSelector(dataForCharts)
    const data = useAppSelector(selectDataApi)
    const category = useAppSelector(selectCategory)
    const chart = useAppSelector(selectChart)
    const time = useAppSelector(selectTime)
    const visible = useAppSelector(visiblePDF)
    const SOV = useAppSelector(selectSwitchSOV)
    const sell = useAppSelector(selectSelloffChecked)
    const switchShares = useAppSelector(selectSwitchShares)
    const dataNames = useAppSelector(selectDataName)
    const dataNamesForCatalog = useAppSelector(selectNamesForCatalog)
    const changeData = useAppSelector(changeDataFactor)
    const salesData = useAppSelector(selectDataSale)
    const salesDataPercent = useAppSelector(selectDataSalePercent)
    const tableCharts = useAppSelector(selectTableChartCategories)
    //const yaxis = useAppSelector(selectYaxis)

    const [hoverAnno, setHoverAnno] = useState(-1)
    const [clickAnno, setClickAnno] = useState<any>([])
    /*const func = async () => {
        let legendItem = await document.getElementsByClassName('apexcharts-legend-series')
        if (legendItem.length) {
            for(let i = 0; i < legendItem.length; i++) {
                legendItem[i].addEventListener('mouseenter', () =>setHoverAnno(i))
            }
        }
        return legendItem
    }

    func()*/

   /* const changeData = data.map((el:dataType) => {
        if (chart === 'АКБ' && el.name === 'Wildberries') {
            return {
                ...el, data: el.data.map((item) => {
                    return [item[0], item[1] - 10000000]
                })
            }

        } else return el
    })*/

    let dataForCatalog: dataType[] = []
    tableCharts ?
    dataForCatalog = dataCharts[0] :
    dataForCatalog = dataForCatalogOrigin

    let dataCatalogSell: dataType[] = []
    tableCharts ?
    dataCatalogSell = dataCharts[0].concat(salesData) :
    dataCatalogSell = dataForCatalog.concat(salesData)


    const dispatch = useAppDispatch()

    const yaxis = settingsYaxis(category, chart, data, dataForCatalog, salesData, SOV, sell, dataCatalogSell)

    const dashArray = settingsStrokeDashArray(chart, data, dataForCatalog, salesData, dataCatalogSell, sell)

    const strokeWidth = settingsStrokeWidth(chart, category, data, dataForCatalog, salesData, dataCatalogSell, sell)

    const colors = settingsColors(category, data)

    const colorsForCatalog = settingsColors(category, dataForCatalog)

    const colorsForSales = settingsColors(category, salesData)

    const periodDate = settingsPeriod(data)

    const toolBar = visible?settingsToolBar():false

    const columnWidth = settingsColumnWidth(chart)

    const orientation = settingsPlotOptionsOrientation(chart)

    const rangeArray = dataForGrowingMarket(data)

    const fill = settingsFill(chart, data)

    const offsetX = offset(chart)

    const formTime = valueOf(time)

    const [value, setValue] = useState<any>(formTime);

    //const tickAmount = getTickAmount(chart==='Каталог' ? dataForCatalog : data)

    const nameIndex = (name:string) => {
        return dataNames.indexOf(name)
    }
    const nameIndexCat = (name:string) => {
        return dataNamesForCatalog.indexOf(name)
    }

    const getColors = () => {
        if (chart === 'Каталог' && !sell) {
            return colorsForCatalog
        }
        if (chart === 'Каталог' && sell) {
            return colorsForCatalog.concat(colorsForSales)
        }
        if (chart === 'Каталог1') {
            return colorsForSales
        }
        return colors
    }

    const points = () => {
        let res = []
        if (chart === 'Рост рынка'&& data.length) {
            res = setPoints(rangeArray, data)
        }
        if ((chart === 'Рынок, темпы' || chart === 'Рынок, руб' || chart === 'АКБ') && data.length) {
            res = setPointsForDataLabels(data, chart, category, yaxis, changeData, clickAnno)
        }
        if ((chart === 'Каталог' && !sell) && dataForCatalog.length) {
            res = setPointsForDataLabels(dataForCatalog, chart, category, yaxis, changeData, clickAnno, tableCharts)
        }
        if ((chart === 'Каталог' && sell) && dataCatalogSell.length) {
            res = setPointsForDataLabels(dataCatalogSell, chart, category, yaxis, changeData, clickAnno, tableCharts)
        }
        if ((chart === 'Каталог1') && salesData.length) {
            res = setPointsForDataLabels(salesData, chart, category, yaxis, changeData, clickAnno)
        }
        if ((chart === 'АКБ') && data.length) {
            res = setPointsForDataLabels(data, chart, category, yaxis, changeData, clickAnno, )
        }
        return res
    }
        const period = getPeriod(time)
        const tickAmountTime = +(period/86400000).toFixed()
        const tickAmountWeek = +(period/604800000).toFixed()
        const offsetXLab = offsetXLabels(period)

const options = {
    chart: {
        id: 'chart1',
        events: {
            zoomed: (chartContext: any, {xaxis, yaxis}: any) => {
                //dispatch(changeTimeValue(formatArrayTime(xaxis)))
                dispatch(zoomAC(xaxis.min?formatArrayTime(xaxis):time))
            },
            legendClick: function (chartContext: any, seriesIndex: number, config: any) {
               return clickAnno.includes(seriesIndex) ? setClickAnno(clickAnno.filter((el: number) => el !== seriesIndex)) : setClickAnno([...clickAnno, seriesIndex])
            },
            beforeResetZoom: function (chartContext: any, opts: any) {
                //dispatch(changeTimeValue(defaultTime))
                return onChange(formTime)
            }
        },
        animations: {
            enabled: false,
        },
        fontFamily: 'SB Sans Text, Helvetica, sans-serif',
        type: 'line' as 'line',
        toolbar: {
            show: false,
            offsetY: -20,
            tools: {
                download: false,
                zoomin: false,
                zoomout: false,
            }
        },
        zoom: {
            enabled: false
        }
    },
    annotations: {
        xaxis: weekAnnotations(formTime, period)[0],
        points: points(),
    },
    stroke: {
        dashArray,
        width: strokeWidth
    },
    grid: {
        borderColor: '#202020',
        padding: {
            left: chart!=='АКБ %'?0:0,
            right: chart!=='АКБ %'?0:0
        },
        xaxis: {
            lines: {
                show: false
            }
        },
        yaxis: {
            lines: {
                show: true
            }
        }
    },
    plotOptions: {
        bar: {
            columnWidth,
            dataLabels: {
                position: 'top',
                orientation: orientation as 'vertical' | 'horizontal',

            },

        }
    },
    fill: fillForColumns(chart, fill),
    dataLabels: {
        enabled: chart === 'АКБ %',
        textAnchor: 'middle' as 'middle',
        offsetY: -5,
        offsetX,
        formatter: function (val: any, opts: any) {
             if (opts.w.globals.initialSeries[opts.seriesIndex].type === undefined) {
                 return val + '%'
             }
            return formatGrade(+val)
        },
        style: {
            fontSize: FONT_SIZE_DATA_LABELS,
            fontWeight: 600,
        },
        background: {
            enabled: true,
            borderRadius: 10,
            borderWidth: .2,
        },

    },
    markers: {
        size: 2
    },
    colors: getColors(),
    legend: {
        position: 'top' as 'top',
        showForSingleSeries: true,
        formatter: function(seriesName: any, opts: any) {
            let res = seriesName
            if (opts.w.globals.initialSeries[opts.seriesIndex].type === undefined) {
                res = seriesName + ' (sale)'
            }
            return res
        },
        horizontalAlign: 'left' as 'left',
        showForZeroSeries: true,
        fontSize: '14px',
        labels: {
            colors: '#FFFFFF'
        },
        onItemClick: {
            toggleDataSeries: true
        },
        onItemHover: {
            highlightDataSeries: false
        },
    },
    yaxis: yaxis.length ? yaxis : [{show: false}],
    xaxis: {
        type: 'numeric' as const,
        min: periodLineChart(time)[0], // + 3 часа
        max: periodLineChart(time)[1], // + 1 день + 3 часа
        tickAmount: window.innerWidth>1000 ? (period > 5500000000 ? tickAmountWeek : tickAmountTime) : tickAmountWeek,
        tickPlacement: 'between',
        axisTicks: {
            show: period < 6200000000 && true,
            offsetY: 20,
            height: 10,
        },
        axisBorder: {
            show: true,
            color: '#F5F5F5',
            height: 3,
            width: '120%',
            offsetX: -100,
            offsetY: -1
        },
        labels: {
            show: true,
            /*formatter: function (value: any, timestamp: any, opts: any) {
                return formatDateForCharts(value)
            },*/
            /*formatter: function (value: any, timestamp: any, opts: any) {
                return formatDateForCategories(value)
            },*/
            formatter: function (value: any, timestamp: any, opts: any) {
                return window.innerWidth>1000 ? (period > 5500000000 ? formatDateForCategories(value) : dayjs(value).format('DD')) : formatDateForCategories(value)
            },
            offsetX: offsetXLab,
            offsetY: 3,
            style: {
                colors: 'white',
                fontWeight: 14,
                fontSize: '12',
            },
            //format: 'dd-MM-yy'

        },
        tooltip: {
            enabled: false,
        },



    } ,
    tooltip: {
        enabled: true,
        theme: 'dark',
        followCursor: false,
        style: {
            fontSize: '14px',

        },
        x: {
            show: true,
            formatter: function (value:any) {
                return formatDateForCategories(value)
            }
        },
        y: {
            formatter: function(value:any, { series, seriesIndex, dataPointIndex, w }: any) {
                if (chart==='АКБ' && seriesIndex === nameIndex('Wildberries')) {
                    if (changeData) {
                        return formatGrade(value + WB_AKB_FACTOR_MIN)
                    }
                    return formatGrade(value + WB_AKB_FACTOR_MIN)
                }
                if (chart==='АКБ' && seriesIndex === nameIndex('Самокат')) {
                    return formatGrade(value + SAM_AKB_FACTOR)
                }
                if (chart==='Каталог' && (seriesIndex === nameIndexCat('Wildberries') || seriesIndex === nameIndexCat('Ozon') || seriesIndex === nameIndexCat('AliExpress')) ) {
                    return ((value + CATALOG_FACTOR)/1000000).toFixed(1)
                }
                if (chart==='Каталог' && (seriesIndex === nameIndexCat('Мегамаркет') || seriesIndex === nameIndexCat('Яндекс.Маркет') ) ) {
                    return (value/1000000).toFixed(1)
                }
                if (chart==='Каталог' &&  seriesIndex > 4 ) {
                    return value.toFixed(2) + '%'
                }

                return formatGrade(value)
            }
        },
        marker: {
            show: true,
        },
        fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
        },

    },
}

const onChange = (value: any,) => {
    setValue(value);
};

const onAfterChange = (value: any) => {
    dispatch(zoomAC([formatTime(value[0]), formatTime(value[1])]))
}


useEffect(() => {
    onChange(formTime)
}, [data])

const getSeriesData = () => {
    if (chart === 'Каталог' && !sell) {
        return dataForCatalog
    }
    if (chart === 'Каталог' && sell) {
        return dataCatalogSell
    }
    if (chart === 'Каталог1') {
        return salesData
    }
    return data
}

const seriesData = getSeriesData()

return (
    <div className={styles.main}>
        <ValuesCharts/>
        {window.innerWidth < 920 && <Slider style={{marginBottom: 30,}}
                                            trackStyle={[{background: '#08A652'}]}
                                            range={{draggableTrack: true}}
                                            value={value}
                                            defaultValue={[formTime[0], formTime[1] /*+ 304800000*/]}
                                            onChange={onChange}
                                            onAfterChange={onAfterChange}
                                            tooltip={{formatter: null}}
                                            min={formTime[0]}
                                            max={formTime[1]}/>}
        <ReactApexChart
            options={options&&options}
            series={seriesData}
            type={'line'}
            height={'100%'}
            style={{color: '#FFFFFF'}}
        />

    </div>
);
}
;

