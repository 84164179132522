import React, {useEffect} from 'react';
import styles from './news.module.sass'
import {Button, Checkbox, DatePicker, Empty, Table, Tooltip} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dateIcon from '../../assets/datePickerIcon.svg'
import closeIcon from "../../assets/close.svg";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {
    fetchDataNewsTC, fetchParamNewsTC,
    setCollapseAC,
    setNewsCategoriesAC,
    setNewsDateAC,
    setNewsSpmAC, setPageAC
} from "../../redux/newsReducer";
import dayjs from "dayjs";
import {
    selectCategoriesNews,
    selectCollapse,
    selectDataNews,
    selectDateNews, selectPageNews, selectParamNews,
    selectSpmNews
} from "../../common/selectors";
import {NewsItem} from "./newsItem";
import {lightColor, marketPlacesColors, superMarketsColors} from "../../common/optionsForColors";
import isoWeek from 'dayjs/plugin/isoWeek';
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {NewsItemType} from "../../common/types";
dayjs.extend(isoWeek);

const {RangePicker} = DatePicker

export const News = () => {

    const dispatch = useAppDispatch()

    const date = useAppSelector(selectDateNews)
    const news = useAppSelector(selectDataNews)
    const collapse = useAppSelector(selectCollapse)
    const checkedCategories = useAppSelector(selectCategoriesNews)
    const checkedSpm = useAppSelector(selectSpmNews)
    const page = useAppSelector(selectPageNews)
    const param = useAppSelector(selectParamNews)
    const filteredCategories = checkedCategories.slice(page, page + 4)

    useEffect(() => {
        dispatch(fetchParamNewsTC())
        dispatch(fetchDataNewsTC({from_date: date[0], to_date: date[1], category: checkedCategories, spm: checkedSpm}))
    }, [date, checkedCategories, checkedSpm, page]);

    function customFormat(value: any) {
        return value.format("YYYY-MM-DD ");
    }

    const onChangeHandler = (value: any, dateString: any) => {
        const start = dayjs(dateString[0]).day(1).format('YYYY-MM-DD')
        const end = dayjs(dayjs(dateString[1]).valueOf() - 1).day(7).format('YYYY-MM-DD') // смещение на 1 мс
        dispatch(setNewsDateAC([start, end]))
    }

    const weeksNews: string[] = []
    news.forEach((el) => {
        if (!weeksNews.includes(el.num_week)) {
            weeksNews.push(el.num_week)
        }
    })
    const columnWidth = window.innerWidth <= 1440 ? window.innerWidth/filteredCategories.length : 1440/filteredCategories.length
    const filteredNews: NewsItemType[] = news.filter((el) => filteredCategories.includes(el.category))

    const dataTables = weeksNews.reverse().map((week) => {
        const [weekVar, year] = week.split('_').map(Number)

        const startOfWeek = dayjs().year(year).isoWeek(weekVar).startOf('isoWeek')
        const endOfWeek = dayjs().year(year).isoWeek(weekVar).endOf('isoWeek')

        const formattedStart = startOfWeek.format('DD.MM.YYYY')
        const formattedEnd = endOfWeek.format('DD.MM.YYYY')

        const weekStr = `${formattedStart} - ${formattedEnd}`

        const columns: any = filteredCategories.map((el, index) => (
            {
                key: index,
                title: el,
                dataIndex: el,
                width: columnWidth,
            }
        ))
        const platformsNews: string[] = []
        filteredNews.filter((el) => el.num_week === week).forEach((el) => {
            if (!platformsNews.includes(el.spm)) {
                platformsNews.push(el.spm)
            }
        })

        !columns.find((el: any) => el.key === -1) && columns.unshift({   // find из-за двойного рендера (пофиксить)
            key: -1,
            title: '',
            dataIndex: 'date',
            width: 20,
            render: (_: any, __: any, index: any) => ({
                children: index === 0 ? <div style={{
                        position: "sticky",
                        top: 70,
                        transform: "rotate(180deg)", // Поворот текста
                        writingMode: "vertical-rl", // Вертикальное направление текста
                        textAlign: "center", // Центровка текста
                        maxWidth: "20px", // Установка ширины колонки
                        whiteSpace: "nowrap", // Запрет переноса текста
                        overflow: "hidden", // Обрезка, если текст не помещается
                    }}>{weekStr}</div> : null,
                    props: {rowSpan: index === 0 ? platformsNews.length*2 : 0},
                }),
            })
        let dataSource: any[] = platformsNews.map((pl) => {
            let res = {key: pl}

            columns.forEach((el: any) => {
                if (el.dataIndex !== 'date') {
                    res = {...res, [el.dataIndex]: []}
                }
            })
            return res
        })
        const result = dataSource.map((data) => {
            filteredNews.forEach((el) => {
                if (el.spm === data.key && el.num_week === week && checkedCategories.includes(el.category)) {
                    data = {...data, [el.category]: [...data[el.category], {get_date: el.get_date, title: el.title, content: el.content, links: el.links}]}
                }
            })
            return data
        })

        const data = result.map((el) => {
            Object.keys(el).forEach((item) => {
                if (item !== 'key') {
                    return el[item] = <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        {el[item].map((i: any) => {
                            const color = marketPlacesColors.concat(superMarketsColors).find((spm) => spm.name === el.key)
                            return  <div >
                                <NewsItem data={i} color={color?.color}/>
                            </div>
                        })}
                    </div>
                }
            })
            return el
        })

        return {
            columns,
            dataSource: data
        }
    })

    function CustomRow(props: any) {
        const color = marketPlacesColors.concat(superMarketsColors).find((spm) => spm.name === props['data-row-key'])
            return (
                <Tooltip title={props['data-row-key']} color={color?.color} overlayInnerStyle={{color: color?.color === lightColor ? '#000' : '#FFF'}}>
                    <tr {...props} />
                </Tooltip>
            );
    }

    const optionsCategories = param.category?.length ? param.category.map(el => ({label: el, value: el})) : [{label: '', value: ''}]

    const optionsSpm = param.spm?.length ? param.spm.map(el => ({label: el, value: el})) : [{label: '', value: ''}]


    const collapseHandler = () => {
        dispatch(setCollapseAC(!collapse))
    }

    const onChangeCategoriesHandler = (categories: CheckboxValueType[]) => {
        dispatch(setNewsCategoriesAC(categories))
    }

    const onChangeSpmHandler = (spm: CheckboxValueType[]) => {
        dispatch(setNewsSpmAC(spm))
    }

    return (
        <div className={styles.main}>
            <div className={styles.topBlock}>
                <div className={styles.picker}>
                    <RangePicker
                        locale={locale}
                        suffixIcon={<img src={dateIcon} alt={'select'}/>}
                        picker={'week'}
                        style={{maxWidth: '320px'}}
                        allowClear={{
                            clearIcon: <img className={styles.closeIcon} src={closeIcon} alt={'clear'}/>
                        }}
                        format={customFormat}
                        onChange={onChangeHandler}
                        defaultValue={[dayjs(date[0]), dayjs(date[1])]}
                    />
                </div>
                <div className={styles.titleBlock}>
                    <div className={styles.title}>Новости</div>
                    <div className={styles.divider}></div>
                </div>
            </div>
            <div className={styles.settings}>
                <div className={styles.topBlockSettings}>
                    <div>
                        {<Checkbox.Group
                            onChange={onChangeCategoriesHandler}
                            options={optionsCategories}
                            value={checkedCategories}
                        />}
                    </div>
                    <div>
                        <Button type={'link'} onClick={collapseHandler}>{!collapse ? 'Свернуть все' : 'Развернуть все'}</Button>
                    </div>
                </div>
                <div className={styles.bottomBlockSettings}>
                    <div>
                        <Checkbox.Group
                            onChange={onChangeSpmHandler}
                            options={optionsSpm}
                            value={checkedSpm}
                        />
                    </div>
                    {checkedCategories.length > 4 &&  <div className={styles.buttons}>
                        <Button type={'link'} onClick={() => page > 0 && dispatch(setPageAC(page - 4))}><LeftOutlined /></Button>
                        <div className={styles.numb}>{`${page ? page/4 + 1 : 1} из ${Math.ceil(checkedCategories.length/4)}`}</div>
                        <Button type={'link'} onClick={() => page <= checkedCategories.length - 5 && dispatch(setPageAC(page + 4))}><RightOutlined /></Button>
                    </div>}
                </div>

            </div>
            <div className={styles.content}>
                <div className={styles.tableHead}>
                    {filteredCategories.map((el) => {
                        return <div className={styles.tableItem} style={{width: '100%'}}>
                            {el}
                        </div>
                    })}
                </div>

                     {dataTables.map((table) => table.dataSource.length ? <div style={{borderBottom: '5px solid white'}}><Table
                         columns={table.columns}
                         dataSource={table.dataSource}
                         pagination={false}
                         showHeader={false}
                         size={'small'}
                         components={{
                             body: {
                                 row: CustomRow
                             }
                         }}
                     />
                     </div>: <div/>)}

            </div>
        </div>
    );
};

