import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AppRootStateType} from "./store";
import {DataBannersType, DataTableRequestType, DataTableResType, DataTableSpmResType} from "../common/types";
import {dataApi} from "../api/dataApi";
import {marketPlacesColors, superMarketsColors} from "../common/optionsForColors";
import {loadingBannersAC, loadingTablesAC, setErrorAC} from "./authReducer";
import {AxiosError} from "axios";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {plainOptionsMps, plainOptionsSpm} from "../common/optionsTableShares";
import {bannerApi} from "../api/bannerApi";


/*type DataTable = {
    dataAlways: DataTableSpmResType[],
    dataWeekly: DataTableSpmResType[],
    dataOther: DataTableSpmResType[],
}*/

type InitialStateType = {
    dataValueTable: DataTableResType[],
    dataValueTableSpm: /*DataTable*/DataTableSpmResType[],
    sortTitle: {name: string, direction: boolean},
    filterTitleMps: CheckboxValueType[],
    filterTitleSpm: CheckboxValueType[],
    viewCheckBoxes: string[]
    countProd: boolean
    banners: {urls: string[]}
    postBanners: boolean,
    slide: number
    params: {
        name_sale: string,
        spm: string,
        from_date: string,
        to_date: string,
        from_date_sale: string,
        to_date_sale: string
    },
    error: string
}

export const fetchDataTable = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataTable', async (param: DataTableRequestType,  {dispatch, rejectWithValue}) => {
    dispatch(loadingTablesAC('loading'))
    try {
        const res = await dataApi.getDataTable(param)
        const dataWithColors:DataTableResType[] = []
        plainOptionsMps.forEach((el) => {
            res.data.forEach((item:DataTableSpmResType) => {
                return item.spm === el ? dataWithColors.push(item):item
            })
        })
        dataWithColors.map((el: any) => {
            el.colors = ''
            el.text = ''
            el.altColor = ''
            marketPlacesColors.forEach((item) => el.spm === item.name ? (el.colors = item.color, el.altColor = item.altColor):item)
            el.spm === 'Яндекс.Маркет' ? el.text = '#202020' : el.text = '#F5F5F5'
        })
        dispatch(loadingTablesAC('succeeded'))
        return dataWithColors
    }catch(err) {
        dispatch(loadingTablesAC('failed'))
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)

    }
})
export const fetchDataTableSpm = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataTableSpm', async (param: DataTableRequestType,  {dispatch, rejectWithValue}) => {
    dispatch(loadingTablesAC('loading'))
    try {
        const res = await dataApi.getDataTableSpm(param)
        const data:DataTableSpmResType[] = []
        plainOptionsSpm.forEach((el) => {
            res.data.forEach((item:DataTableSpmResType) => {
                return item.spm === el ? data.push(item):item
            })
        })
        data.map((el: any) => {
            el.colors = ''
            el.text = ''
            el.altColor = ''
            superMarketsColors.forEach((item) => el.spm === item.name ? (el.colors = item.color, el.altColor = item.altColor) :item)
            el.spm === 'Яндекс.Маркет15' ? el.text = '#202020' : el.text = '#F5F5F5'
        })

        let names = data.map((el) => el.spm)
        let filterNames = Array.from(new Set(names))
        dispatch(selectCheckboxesAC(filterNames))

        /*let always:DataTableSpmResType[]  = []
        let weekly:DataTableSpmResType[] = []
        let other:DataTableSpmResType[] = []*/
        /*data.forEach((el: DataTableSpmResType) => {
            if (el.period_date === 'Постоянно') {
                always.push(el)
            }
            if (el.period_date === 'Еженедельно') {
                weekly.push(el)
            }
            if (el.period_date === null) {
                other.push(el)
            }

        })*/

        data.map((el: DataTableSpmResType) => {
            if (el.period_date) {
                el.dateInfo = el.period_date
            }
            if (!el.period_date) {
                el.dateInfo = el.to_date.toString()
            }
        })
        dispatch(loadingTablesAC('succeeded'))
        return /*[always, weekly, other]*/ data
    }catch(err) {
        dispatch(loadingTablesAC('failed'))
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)

    }
})

export const fetchBanners = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchBanners', async (param: DataBannersType,  {dispatch, rejectWithValue}) => {
    //dispatch(loadingTablesAC('loading'))
    try {
        const res = await bannerApi.getData(param)
       // dispatch(loadingBannersAC('succeeded'))
        return res.data
    }catch(err) {
        dispatch(loadingTablesAC('failed'))
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)

    }
})

export const postBanners = createAsyncThunk<any, any, { state: AppRootStateType }>('postBanners', async (param: DataBannersType,  {dispatch, rejectWithValue, getState}) => {
    dispatch(loadingBannersAC('loading'))
    try {
        const {table} = getState()
        const res = await bannerApi.postData(param)
        dispatch(clearBannersAC({urls: []}))
        setTimeout(() => {
            dispatch(fetchBanners(table.params))
            dispatch(loadingBannersAC('succeeded'))
        }, 2000)

        dispatch(bannerStatusAC(!table.postBanners))
        return res.status
    }catch(err) {
        dispatch(loadingBannersAC('failed'))
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)

    }
})


export const slice = createSlice({
    name: 'table',
    initialState: {
        dataValueTable: [],
        dataValueTableSpm: [] /*{
            dataAlways: [],
            dataWeekly: [],
            dataOther: []
        }*/,
        sortTitle:{name:'get_date_row', direction: false},
        filterTitleMps: plainOptionsMps,
        filterTitleSpm: plainOptionsSpm,
        viewCheckBoxes: [],
        countProd: false,
        banners: {urls: []},
        postBanners: false,
        slide: 0,
        params: {
            name_sale: '',
            spm: '',
            from_date: '',
            to_date: '',
            from_date_sale: '',
            to_date_sale: ''
        },
        error: ''
    } as  InitialStateType,
    reducers: {
        selectSortTitleAC(state, action) {
            state.sortTitle = action.payload
        },
        selectFilterTitleAC(state, action) {
            state.filterTitleMps = action.payload
        },
        selectFilterTitleSpmAC(state, action) {
            state.filterTitleSpm = action.payload
        },
        selectCheckboxesAC(state, action) {
            state.viewCheckBoxes = action.payload
        },
        selectCountProdAC(state, action) {
            state.countProd = action.payload
        },
        clearStatusAC(state, action) {
            state.postBanners = action.payload
        },
        bannerStatusAC(state, action) {
            state.postBanners = action.payload
        },
        selectSlideAC(state, action) {
            state.slide = action.payload
        },
        selectParamsAC(state, action) {
            state.params = action.payload
        },
        clearTableErrorAC(state, action) {
            state.error = action.payload
        },
        clearBannersAC(state, action) {
            state.banners = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDataTable.fulfilled, (state, action) => {
                state.dataValueTable = action.payload
            })
            .addCase(fetchBanners.fulfilled, (state, action) => {
                state.banners = action.payload
            })
            .addCase(fetchBanners.rejected, (state, action) => {
                state.banners = {urls: []}
            })
            .addCase(postBanners.fulfilled, (state, action) => {
                //state.postBanners = action.payload
            })
            .addCase(postBanners.rejected, (state, action:any) => {
                state.error = action.payload.statusText
            })
            .addCase(fetchDataTableSpm.fulfilled, (state, action) => {
                /*state.dataValueTableSpm.dataAlways = action.payload[0]
                state.dataValueTableSpm.dataWeekly = action.payload[1]
                state.dataValueTableSpm.dataOther = action.payload[2]*/
                state.dataValueTableSpm = action.payload
            })
    }
})

export const tableReducer = slice.reducer
export const {selectSortTitleAC,
    selectFilterTitleAC,
    selectCheckboxesAC,
    clearStatusAC,
    selectSlideAC,
    selectParamsAC,
    bannerStatusAC,
    clearTableErrorAC,
    clearBannersAC,
    selectCountProdAC} = slice.actions