import React from 'react';
import styles from './header.module.sass'
import {Button} from "antd";
import {LogoutOutlined} from "@ant-design/icons";
import {NavLink, useNavigate} from "react-router-dom";
import {Logo} from "../content/logo";
import {isLoggedInAC} from "../../redux/authReducer";
import {changeTimeValue, choiceOfPlatformAC, selectChartAC, switchCategoriesAC} from "../../redux/optionsReducer";
import {AUTH, CHARTS, MAP, NEWS} from "../../common/constants";
import {
    defaultAllChartTime,
    defaultCityChartTime,
    defaultLineChartTime,
    defaultTime
} from "../../common/optionsForDate";
import {
    changeColumnCityChartAC,
    changePriceColumnForAllChartAC,
    changePriceLineChartAC
} from "../../redux/priceReducer";
import {useAppDispatch} from "../../redux/store";
export const Header = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const onClickHandler = () => {
        localStorage.removeItem('JWT');
        dispatch(isLoggedInAC(false))
        dispatch(switchCategoriesAC(false))
        dispatch(selectChartAC('Рынок, темпы'))
        navigate(AUTH)
        sessionStorage.clear()
        dispatch(changeTimeValue(defaultTime))
        dispatch(changePriceLineChartAC(defaultLineChartTime))
        dispatch(changePriceColumnForAllChartAC(defaultAllChartTime))
        dispatch(changeColumnCityChartAC(defaultCityChartTime))
        dispatch(switchCategoriesAC(false))
        dispatch(choiceOfPlatformAC('stocks'))
    }

    const setActive = (nav:{isActive: boolean}) => nav.isActive ? `${styles.link} ${styles.active}` : `${styles.link}`;

    return (
        <div className={styles.topSection}>
            <div className={styles.buttons}>
                <Button type={'link'} icon={<LogoutOutlined />} onClick={onClickHandler}>Выйти</Button>
                <NavLink to={CHARTS} className={setActive}>Графики</NavLink>
                <NavLink to={MAP} className={setActive}>Карты</NavLink>
            </div>
            <div className={styles.logo}>
                <Logo/>
            </div>
        </div>
    );
};

