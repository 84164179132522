import React from 'react';

import ReactApexChart from "react-apexcharts";
import {formatGrade} from "../../../../common/optionsForCategories/table";
import {useAppSelector} from "../../../../redux/store";
import {selectCategories, selectChart, selectDataTable} from "../../../../common/selectors";
import {EmptyComponent} from "../../../../common/empty";
import {DataCategoriesResType} from "../../../../common/types";
import {lightColor} from "../../../../common/optionsForColors";

type SeriesType = {
    name: string,
    data: number[]
}

export const CategoriesColumnChart = () => {

    const chart = useAppSelector(selectChart)
    const catData = useAppSelector(selectCategories)
    const dataTable = useAppSelector(selectDataTable)

    const categoriesData: DataCategoriesResType[] = [...catData]

    const series: SeriesType[] = [{
        name: 'Wildberries',
        data: []
    },
    {
        name: 'Ozon',
        data: []
    },
    {
        name: 'Яндекс.Маркет',
        data: []
    },
    {
        name: 'AliExpress',
        data: []
    },
    {
        name: 'Мегамаркет',
        data: []
    },
    ]
    dataTable.forEach((el:any) => (
        series[0].data.push(el.wb),
        series[1].data.push(el.ozon),
        series[2].data.push(el.ym),
        series[3].data.push(el.ae),
        series[4].data.push(el.mm)
    ))

    //addDataForTable(categoriesData)

    const categor = dataTable.map((el:any) => {
        return el.name
    })

    let stacked = false
    chart === 'Данные, %' ?  stacked = true : stacked = false

    let dataLabels = true
    chart === 'Данные, %' ? dataLabels = true : dataLabels = false

    let colorsDataLabels: string[] = []
    series.forEach((el) =>  colorsDataLabels.push(el.name === 'Яндекс.Маркет' ? '#202020' : '#F5F5F5'))

    const options = {
        chart: {
            type: 'bar' as 'bar',
            height: 350,
            stacked,
            stackType: '100%' as '100%',
            toolbar: {
                show: false
            }
        },
        colors: ['#C811aa','#0059FF',lightColor,'#e74e0a','#24b23e'],
        plotOptions: {
            bar: {
                horizontal: true,
                    columnWidth: '55%',
                    endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: dataLabels,
            style: {
                colors:  colorsDataLabels
            }

        },
        stroke: {
            show: true,
                width: 2,
                colors: ['transparent']
        },
        xaxis: {
            categories: categor,
            labels: {
                style: {
                    colors: ['#F5F5F5']
                },
                formatter: function (val: any) {
                    return  chart === 'Данные, %' ?  val + "%" : val
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#F5F5F5',

                }
            }

        },
        legend: {
            labels: {
                colors: '#F5F5F5',
            },
            position: 'top' as 'top',
            fontSize: '14px',
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            theme: 'dark',
            y: {
                formatter: (value: number) => formatGrade(value),
            },
        },
        responsive: [{
            breakpoint: 500,
            options: {
                yaxis: {
                    labels: {
                        show: false
                    }

                },

            }
        }]
    }

    return (
        <div >
            {catData.length?<ReactApexChart options={options} series={series} type="bar" height={600}/>:<EmptyComponent/>}
        </div>
    );
};

