import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AppRootStateType} from "./store";
import {DataCategoriesRequestType, DataCategoriesResType, dataType} from "../common/types";
import {dataApi} from "../api/dataApi";
import {loadingCategoriesAC, loadingCategoriesCartsAC, loadingChartsAC, setErrorAC} from "./authReducer";
import {AxiosError} from "axios";
import dayjs from "dayjs";
import {firstDayOfTheWeek} from "../common/optionsForDate";
import {formatGrade, namesForTotalTable} from "../common/optionsForCategories/table";
import {CATALOG_FACTOR} from "../common/constants";
import {orderData} from "../common/orderData";


type InitialStateType = {
    dataValueCategories: DataCategoriesResType[]
    dataForTable: any,
    dataForTotalTable: any,
    dataForCharts: dataType[][]
    dataForCatalog: dataType[]
    secondDataForCatalog: dataType[]
    namesForCatalog: string[]
    mps: string
    dateForCategoriesCharts: string[]
    tableChartCategories: string,
    dates: string[]
    error: number | null
}


export const fetchDataCategories = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataCategories', async (param: DataCategoriesRequestType,  {dispatch, rejectWithValue, getState}) => {
    dispatch(loadingCategoriesAC('loading'))
    try {
        const res = await dataApi.getDataCategories(param)
        dispatch(loadingCategoriesAC('succeeded'))
        return res.data
    }catch(err) {
        dispatch(loadingCategoriesAC('failed'))
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)
    }
})

export const fetchDataForCatalog = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataCatalog', async (param: DataCategoriesRequestType,  {dispatch, rejectWithValue, getState}) => {
    dispatch(loadingChartsAC('loading'))
    try {
        const {options} = getState()
        const res = await dataApi.getDataCategories(param)
        res.data.map((el:DataCategoriesResType) => el.date_parse = firstDayOfTheWeek(el.date_parse) + 309600000) // середина недели
        const data: DataCategoriesResType[] = []           // обычные данные
        let secondData: DataCategoriesResType[] = []      // распродажи
        res.data.forEach((el: DataCategoriesResType) => {
            return el.name_category === 'Распродажи' ? secondData.push(el) : data.push(el)
        })
        let dataName:string[] = []               // массив названий маркетов
        let secondDataName:string[] = []               // массив названий маркетов, учавствующих в распродаже
        let dataForCharts:dataType[] = []     // массив для отрисовки графиков
        let secondDataForCharts:dataType[] = []     // массив для отрисовки графиков, распродажи


        data.sort((a:DataCategoriesResType,b:DataCategoriesResType) => a.date_parse - b.date_parse)
        secondData.sort((a:DataCategoriesResType,b:DataCategoriesResType) => a.date_parse - b.date_parse)

        data.map((el: DataCategoriesResType) => !dataName.includes(el.spm) && dataName.push(el.spm))
        secondData.map((el: DataCategoriesResType) => !secondDataName.includes(el.spm) && secondDataName.push(el.spm))

        dataName.forEach((el) => {
            dataForCharts.push({
                name: el,
                type: 'line',
                data: []
            })
        })
        secondDataName.forEach((el) => {
            secondDataForCharts.push({
                name: el,

                data: []
            })
        })

        data.forEach((el:DataCategoriesResType) => {
            dataForCharts.forEach((item) => el.spm === item.name && !item.data.find((a) => a[0] === dayjs(el.date_parse).valueOf()) ? item.data.push([dayjs(el.date_parse).valueOf(), el.count_positions]): item)
        })
        secondData.forEach((el:DataCategoriesResType) => {
            secondDataForCharts.forEach((item) => el.spm === item.name && !item.data.find((a) => a[0] === dayjs(el.date_parse).valueOf()) ? item.data.push([dayjs(el.date_parse).valueOf(), el.count_positions]): item)
        })


        let filteredData: dataType[] = []     // фильтрованный массив по маркетам
        let secondFilteredData: dataType[] = []     // фильтрованный массив по маркетам

        dataForCharts.forEach((el) => options.marketPlacesNames.includes(el.name) && filteredData.push(el))
        secondDataForCharts.forEach((el) => options.marketPlacesNames.includes(el.name) && secondFilteredData.push(el))
        secondFilteredData.map((el) => {
            filteredData.forEach((item) => {
                if (el.name === item.name) {
                    el.data.map((value, index) => item.data.forEach((val) => value[0] === val[0] ? [...value, value[1] = +(value[1] / val[1] * 100).toFixed(2)] : val))
                }
            })
        })
        /*if (options.switches.selloff) {
            filteredData = filteredData.concat(secondFilteredData)
        }*/



        const changeData: dataType[] = filteredData.map((el) => {
            if ((el.name === 'Wildberries' || el.name === 'Ozon' || el.name === 'AliExpress') && el.type === 'line') {
                return {
                    ...el, data: el.data.map((item) => {
                        return [item[0], item[1] -  CATALOG_FACTOR]
                    })
                }
            } else return el
        })

        const sortedData: any[] = []
        orderData.forEach((el) => {
            changeData.forEach((item) => el === item.name ? sortedData.push(item) : item)
        })
        dispatch(loadingChartsAC('succeeded'))
        return [sortedData, dataName, secondFilteredData]
    }catch(err) {
        dispatch(loadingChartsAC('failed'))
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)
    }
})

export const fetchDataTableCategories = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataTableCategories', async (param: DataCategoriesRequestType,  {dispatch, rejectWithValue, getState}) => {
    //dispatch(loadingCategoriesAC('loading'))
    try {
        const res = await dataApi.getDataCategories(param)
        let contentForTotalTable: any = [
            {
                key: 1,
                name: 'Wildberries',
                one_level: 0,
                all_levels: 0,
                products: 0
            },
            {
                key: 2,
                name: 'Ozon',
                one_level: 0,
                all_levels: 0,
                products: 0
            },
            {
                key: 3,
                name: 'Яндекс.Маркет',
                one_level: 0,
                all_levels: 0,
                products: 0
            },
            {
                key: 4,
                name: 'AliExpress',
                one_level: 0,
                all_levels: 0,
                products: 0
            },
            {
                key: 5,
                name: 'Мегамаркет',
                one_level: 0,
                all_levels: 0,
                products: 0
            },
        ]
        contentForTotalTable.forEach((el: any) => {
            res.data.forEach((item:any) => {
                    if (el.name === item.spm && item.name_category !== 'Распродажи') {
                        return    el.one_level = item.level_1,
                            el.all_levels = formatGrade(item.count_unic_category),
                            el.products = formatGrade(item.count_positions)
                    }
                }

            )
        })
        let namesCategories: any = [
            {key: '1', name: 'Автотовары', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '2', name: 'Бытовая техника', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '3', name: 'Детские товары и игрушки', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '4', name: 'Дом, сад, офис, школа', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '5', name: 'Зоотовары', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '6', name: 'Книги', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '7', name: 'Красота, аптека, здоровье', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '8', name: 'Мебель', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '9', name: 'Обустройство дома и инструмент', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '10', name: 'Одежда и обувь', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '11', name: 'Продукты питания', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '12', name: 'Спорт', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '13', name: 'Электроника', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
            {key: '14', name: 'Ювелирные изделия', ozon: 0, wb: 0, ym: 0, ae: 0, mm: 0},
        ]

        res.data.forEach((el: DataCategoriesResType) => (
            namesCategories.map((item: any, index: number) => el.table_category === item.name && (
                el.spm === 'Wildberries' ? namesCategories[index].wb += el.count_products: el,
                el.spm === 'Ozon' ? namesCategories[index].ozon += el.count_products : el,
                el.spm === 'Яндекс.Маркет' ? namesCategories[index].ym += el.count_products : el,
                el.spm === 'AliExpress' ? namesCategories[index].ae += el.count_products : el,
                el.spm === 'Мегамаркет' ? namesCategories[index].mm += el.count_products : el
            )),
            contentForTotalTable.map((item:any) => el.spm === item.name && el.name_category !== 'Распродажи' ? (item.one_level = el.level_1,
                        item.all_levels = formatGrade(el.count_unic_category),
                        item.products = formatGrade(el.count_positions)) : item

            )
        ))

        return [namesCategories, contentForTotalTable]
    }catch(err) {
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response)
    }
})

export const fetchDataTotalTableCategories = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataTotalTableCategories', async (param: DataCategoriesRequestType,  {dispatch, rejectWithValue, getState}) => {
    //dispatch(loadingCategoriesAC('loading'))
    try {
        const res = await dataApi.getDataCategories(param)
        let contentForTotalTable:any = [
            {
                key: 1,
                name: 'Wildberries',
                one_level: 0,
                all_levels: 0,
                products: 0
            },
            {
                key: 2,
                name: 'Ozon',
                one_level: 0,
                all_levels: 0,
                products: 0
            },
            {
                key: 3,
                name: 'Яндекс.Маркет',
                one_level: 0,
                all_levels: 0,
                products: 0
            },
            {
                key: 4,
                name: 'AliExpress',
                one_level: 0,
                all_levels: 0,
                products: 0
            },
            {
                key: 5,
                name: 'Мегамаркет',
                one_level: 0,
                all_levels: 0,
                products: 0
            },
        ]

        res.data.forEach((el: DataCategoriesResType) => (
            contentForTotalTable.map((item:any) => {
                if (el.spm === item.name && el.name_category !== 'Распродажи')
                    return item.one_level = el.level_1,
                        item.all_levels = formatGrade(el.count_unic_category),
                        item.products = formatGrade(el.count_positions)

            })
        ))

        return contentForTotalTable
    }catch(err) {
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response)
    }
})


export const fetchDataCategoriesForCharts = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataCategoriesForCharts', async (param: DataCategoriesRequestType,  {dispatch, rejectWithValue, getState}) => {
    dispatch(loadingCategoriesCartsAC('loading'))
    try {
        const {options} = getState()
        const res = await dataApi.getDataCategories(param)

        const dateArray = res.data.map((el:DataCategoriesResType) => {
            return el.date_parse
        })
        res.data.map((el:DataCategoriesResType) => el.date_parse = firstDayOfTheWeek(el.date_parse) + 309600000) // середина недели
        const dataCategory = res.data.filter((el: DataCategoriesResType) => el.name_category !== 'Распродажи')
        /*-------------------------------------разбивка табличной категории по маркетплейсам---------------------------*/

        const wildberries = dataCategory.filter((el: DataCategoriesResType) => el.spm === 'Wildberries')
        const ozon = dataCategory.filter((el: DataCategoriesResType) => el.spm === 'Ozon')
        const yandex = dataCategory.filter((el: DataCategoriesResType) => el.spm === 'Яндекс.Маркет')
        const aliExpress = dataCategory.filter((el: DataCategoriesResType) => el.spm === 'AliExpress')
        const megaMarket = dataCategory.filter((el: DataCategoriesResType) => el.spm === 'Мегамаркет')
        let series = [
            {
                name: 'Wildberries',
                type: 'line',
                data: [] as number[][]
            },
            {
                name: 'Ozon',
                type: 'line',
                data: [] as [number,number][]
            },
            {
                name: 'Яндекс.Маркет',
                type: 'line',
                data: [] as [number,number][]
            },
            {
                name: 'AliExpress',
                type: 'line',
                data: [] as [number,number][]
            },
            {
                name: 'Мегамаркет',
                type: 'line',
                data: [] as [number,number][]
            },
        ]

        let seriesTotal = [
            {
                name: 'Wildberries',
                data: [] as number[][]
            },
            {
                name: 'Ozon',
                data: [] as [number,number][]
            },
            {
                name: 'Яндекс.Маркет',
                data: [] as [number,number][]
            },
            {
                name: 'AliExpress',
                data: [] as [number,number][]
            },
            {
                name: 'Мегамаркет',
                data: [] as [number,number][]
            },
        ]

        let series2 = [
            {
                name: 'Wildberries',
                data: [] as number[][]
            },
            {
                name: 'Ozon',
                data: [] as [number,number][]
            },
            {
                name: 'Яндекс.Маркет',
                data: [] as [number,number][]
            },
            {
                name: 'AliExpress',
                data: [] as [number,number][]
            },
            {
                name: 'Мегамаркет',
                data: [] as [number,number][]
            },
        ]


        let wildberriesDate: any = []
        let ozonDate: any = []
        let yandexDate: any = []
        let aliExpressDate: any = []
        let megaMarketDate: any = []

        /*-------------------------------------сборка дат в массивы по маркетплейсам---------------------------*/

        wildberries.map((el: DataCategoriesResType) => wildberriesDate.includes(el.date_parse) ? el : wildberriesDate.push(el.date_parse))
        ozon.map((el: DataCategoriesResType) => ozonDate.includes(el.date_parse) ? el : ozonDate.push(el.date_parse))
        yandex.map((el: DataCategoriesResType) => yandexDate.includes(el.date_parse) ? el : yandexDate.push(el.date_parse))
        aliExpress.map((el: DataCategoriesResType) => aliExpressDate.includes(el.date_parse) ? el : aliExpressDate.push(el.date_parse))
        megaMarket.map((el: DataCategoriesResType) => megaMarketDate.includes(el.date_parse) ? el : megaMarketDate.push(el.date_parse))

        /*-------------------------------------формирование данных по графикам с нулевым показателем категоий: [timestamp, 0](для всех графиков до этого момента)---------------------------*/

        if (wildberriesDate.length) {
            wildberriesDate.forEach((el: any) => series[0].data.push([dayjs(el).valueOf(), 0]))
            wildberriesDate.forEach((el: any) => seriesTotal[0].data.push([dayjs(el).valueOf(), 0]))
            wildberriesDate.forEach((el: any) => series2[0].data.push([dayjs(el).valueOf(), 0]))
        }

        if (ozonDate.length) {
            ozonDate.forEach((el: any) => series[1].data.push([dayjs(el).valueOf(), 0]))
            ozonDate.forEach((el: any) => seriesTotal[1].data.push([dayjs(el).valueOf(), 0]))
            ozonDate.forEach((el: any) => series2[1].data.push([dayjs(el).valueOf(), 0]))
        }

        if (yandexDate.length) {
            yandexDate.forEach((el: any) => series[2].data.push([dayjs(el).valueOf(), 0]))
            yandexDate.forEach((el: any) => seriesTotal[2].data.push([dayjs(el).valueOf(), 0]))
            yandexDate.forEach((el: any) => series2[2].data.push([dayjs(el).valueOf(), 0]))
        }

        if (aliExpressDate.length) {
            aliExpressDate.forEach((el: any) => series[3].data.push([dayjs(el).valueOf(), 0]))
            aliExpressDate.forEach((el: any) => seriesTotal[3].data.push([dayjs(el).valueOf(), 0]))
            aliExpressDate.forEach((el: any) => series2[3].data.push([dayjs(el).valueOf(), 0]))
        }

        if (megaMarketDate.length) {
            megaMarketDate.forEach((el: any) => series[4].data.push([dayjs(el).valueOf(), 0]))
            megaMarketDate.forEach((el: any) => seriesTotal[4].data.push([dayjs(el).valueOf(), 0]))
            megaMarketDate.forEach((el: any) => series2[4].data.push([dayjs(el).valueOf(), 0]))
        }


        /*-------------------------------------суммирование категорий в массиве с нужной датой ---------------------------*/

        wildberries.map((el: DataCategoriesResType) => series[0].data.sort().forEach((item) => item[0] === dayjs(el.date_parse).valueOf() ? item[1] += el.count_products : item))
        ozon.map((el: DataCategoriesResType) => series[1].data.sort().forEach((item) => item[0] === dayjs(el.date_parse).valueOf() ? item[1] += el.count_products : item))
        yandex.map((el: DataCategoriesResType) => series[2].data.sort().forEach((item) => item[0] === dayjs(el.date_parse).valueOf() ? item[1] += el.count_products : item))
        aliExpress.map((el: DataCategoriesResType) => series[3].data.sort().forEach((item) => item[0] === dayjs(el.date_parse).valueOf() ? item[1] += el.count_products : item))
        megaMarket.map((el: DataCategoriesResType) => series[4].data.sort().forEach((item) => item[0] === dayjs(el.date_parse).valueOf() ? item[1] += el.count_products : item))


        wildberries.map((el: DataCategoriesResType) => seriesTotal[0].data.sort().forEach((item) => item[0] === dayjs(el.date_parse).valueOf() ? item[1] = el.count_positions : item))
        ozon.map((el: DataCategoriesResType) => seriesTotal[1].data.sort().forEach((item) => item[0] === dayjs(el.date_parse).valueOf() ? item[1] = el.count_positions : item))
        yandex.map((el: DataCategoriesResType) => seriesTotal[2].data.sort().forEach((item) => item[0] === dayjs(el.date_parse).valueOf() ?item[1] = el.count_positions : item))
        aliExpress.map((el: DataCategoriesResType) => seriesTotal[3].data.sort().forEach((item) => item[0] === dayjs(el.date_parse).valueOf() ? item[1] = el.count_positions : item))
        megaMarket.map((el: DataCategoriesResType) => seriesTotal[4].data.sort().forEach((item) => item[0] === dayjs(el.date_parse).valueOf() ? item[1] = el.count_positions : item))

        series2[0].data.sort().map((el, index) => el[1] = series[0].data[index][1]/(seriesTotal[0].data[index][1] / 100) )
        series2[1].data.sort().map((el, index) => el[1] = series[1].data[index][1]/(seriesTotal[1].data[index][1] / 100) )
        series2[2].data.sort().map((el, index) => el[1] = series[2].data[index][1]/(seriesTotal[2].data[index][1] / 100) )
        series2[3].data.sort().map((el, index) => el[1] = series[3].data[index][1]/(seriesTotal[3].data[index][1] / 100) )
        series2[4].data.sort().map((el, index) => el[1] = series[4].data[index][1]/(seriesTotal[4].data[index][1] / 100) )

        const integrateSeriesInOptions = (series: any) => {
            return series.filter((el: dataType) => options.marketPlacesNames.includes(el.name))
        }
        const seriesOne = integrateSeriesInOptions(series)
        const seriesAll = integrateSeriesInOptions(seriesTotal)
        const seriesTwo = integrateSeriesInOptions(series2)

        dispatch(loadingCategoriesCartsAC('succeeded'))
        return [seriesOne, seriesAll, seriesTwo, wildberriesDate]
    }catch(err) {
        dispatch(loadingCategoriesCartsAC('failed'))
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)
    }
})

export const slice = createSlice({
    name: 'categories',
    initialState: {
        dataValueCategories: [] ,
        dataForTable: [],
        dataForTotalTable: [],
        dateForCategoriesCharts: [],
        dataForCharts: [],
        dataForCatalog: [],
        secondDataForCatalog: [],
        namesForCatalog: namesForTotalTable,
        mps: 'Wildberries',
        tableChartCategories: '',
        dates: [],
        error: null
    } as  InitialStateType,
    reducers: {
        selectMpsAC(state, action) {
            state.mps = action.payload
        },
        tableChartCategoriesAC(state, action) {
            state.tableChartCategories = action.payload
        },
        setDateForCategoriesChartsAC(state, action) {
            state.dateForCategoriesCharts = action.payload
        },
        clear(state, action) {
            state.dataForCharts = []
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDataCategories.fulfilled, (state, action) => {
                state.dataValueCategories = action.payload
            })
            .addCase(fetchDataCategories.rejected, (state, action:any) => {
                state.error = action.payload.status
            })
            .addCase(fetchDataForCatalog.fulfilled, (state, action) => {
                state.dataForCatalog = action.payload[0]
                state.namesForCatalog = action.payload[1]
                state.secondDataForCatalog = action.payload[2]
            })
            .addCase(fetchDataForCatalog.rejected, (state, action:any) => {
                state.error = action.payload.status
            })

            .addCase(fetchDataTableCategories.fulfilled, (state, action) => {
                state.dataForTable = action.payload[0]
                state.dataForTotalTable = action.payload[1]
            })
            .addCase(fetchDataTableCategories.rejected, (state, action:any) => {
                state.error = action.payload.status
            })
            .addCase(fetchDataCategoriesForCharts.fulfilled, (state, action) => {
                state.dataForCharts = action.payload
                state.dates = action.payload[3]
            })
            .addCase(fetchDataCategoriesForCharts.rejected, (state, action:any) => {
                state.error = action.payload.status
            })

    }
})

export const categoriesReducer = slice.reducer
export const {selectMpsAC, tableChartCategoriesAC, clear} = slice.actions