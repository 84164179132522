import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {NewsItemType, NewsType} from "../common/types";
import {AxiosError} from "axios";
import {newsApi} from "../api/newsApi";
import dayjs from "dayjs";
import {AppRootStateType} from "./store";
import {orderData, sortSpmDataForNews} from "../common/orderData";
import {marketPlacesColors, superMarketsColors} from "../common/optionsForColors";

const endDate = dayjs(dayjs().valueOf() - 1).day(7).format('YYYY-MM-DD')
const start = dayjs(dayjs().valueOf() - 2419200000).day(1).format('YYYY-MM-DD') // -5 недель

export const slice = createSlice({
    name: 'news',
    initialState: {
        dateNews: [start, endDate],  // период
        dataNews: [],                // новости
        categoriesNews: [],           // все активные категории новостей в данный момент (берутся из новостей)
        mpsNews: [],                   // все активные маркетплейсы в данный момент (берутся из новостей)
        spmNews: [],                   // все активные супермаркеты в данный момент (берутся из новостей)
        collapse: true,
        page: 0,
        paramNews: {category: [], spm: [], mps: []} // все категории, супермаркеты и маркетплейсы что есть(даже те, которых нет в новостях), берутся из бэка
    } as NewsType,
    reducers: {
        setNewsDateAC(state, action) {
            state.dateNews = action.payload
        },
        setNewsCategoriesAC(state, action) {
            state.categoriesNews = action.payload
        },
        setNewsMpsAC(state, action) {
            state.mpsNews = action.payload
        },
        setNewsSpmAC(state, action) {
            state.spmNews = action.payload
        },
        setCollapseAC(state, action) {
            state.collapse = action.payload
        },
        setPageAC(state, action) {
            state.page = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDataNewsTC.fulfilled, (state, action) => {
                state.dataNews = action.payload
            })
            .addCase(fetchParamNewsTC.fulfilled, (state, action) => {
                state.paramNews = action.payload
            })

        }

})

export const fetchDataNewsTC = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataNews', async (param: {from_date: string, to_date: string, category: string[], spm: string[]},  {dispatch, rejectWithValue, getState}) => {
    const {news} = getState()
    const {options} = getState()
    try {
        const res = await newsApi.getData(param.from_date, param.to_date, param.category, param.spm)
        const newsData = sortSpmDataForNews(res.data)
        const categoriesNews: string[]= []
        const mps: string[]= []
        const spm: string[]= []
        newsData.forEach((el: NewsItemType) => {
            if (!categoriesNews.includes(el.category)) {
                categoriesNews.push(el.category)
            }
            if (!spm.includes(el.spm) && superMarketsColors.find((spm) => spm.name === el.spm)) {
                spm.push(el.spm)
            }
            if (!mps.includes(el.spm) && marketPlacesColors.find((mps) => mps.name === el.spm)) {
                mps.push(el.spm)
            }
        })
        !news.categoriesNews.length && dispatch(setNewsCategoriesAC(categoriesNews))
        !news.mpsNews.length && dispatch(setNewsMpsAC(mps))
        !news.spmNews.length && dispatch(setNewsSpmAC(spm))

        return newsData
    }catch(err) {
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response)
    }
})

export const fetchParamNewsTC = createAsyncThunk('fetchParamNews', async (_, { rejectWithValue, getState}) => {
    try {
        const res = await newsApi.getNewsParam()

        const mps: string[] = []
        const spm: string[] = []
        orderData.forEach((el) => {
            res.data.spm.forEach((item: string) => {
                if (marketPlacesColors.find((mps) => mps.name === item)) {
                    return el === item ? mps.push(item) : item
                }
                if (superMarketsColors.find((spm) => spm.name === item)) {
                    return el === item ? spm.push(item) : item
                }

            })
        })
        return {...res.data, spm, mps}
    }catch(err) {
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response)
    }
})

export const newsReducer = slice.reducer
export const {setNewsDateAC,
    setNewsCategoriesAC,
    setNewsSpmAC,
    setNewsMpsAC,
    setCollapseAC,
    setPageAC} = slice.actions