import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {
    selectCategoriesNews,
    selectCategory,
    selectDataNews,
    selectDateNews,
    selectMpsNews,
    selectPageNews,
    selectSpmNews
} from "../../common/selectors";
import {fetchDataNewsTC, fetchParamNewsTC} from "../../redux/newsReducer";
import {Table, Tooltip} from "antd";
import {lightColor, marketPlacesColors, superMarketsColors} from "../../common/optionsForColors";
import {formatWeekToISODate} from "../../common/formatWeekToISODate";
import {ItemNews} from "./itemNews";
import dayjs from "dayjs";
import styles from './newsComponent.module.sass'
import {EmptyComponent} from "../../common/empty";

export const NewsComponent = () => {

    const dispatch = useAppDispatch()

    const date = useAppSelector(selectDateNews)
    const news = useAppSelector(selectDataNews)
    const checkedCategories = useAppSelector(selectCategoriesNews)
    const checkedMps = useAppSelector(selectMpsNews)
    const checkedSpm = useAppSelector(selectSpmNews)
    const page = useAppSelector(selectPageNews)
    const category = useAppSelector(selectCategory)

    const weekCount = Math.round((dayjs(date[1]).valueOf() - dayjs(date[0]).valueOf())/604800000)  // количество недель
    const numberWeek = dayjs(date[1]).week()                                                       // номер текущей недели
    const currentYear = dayjs(date[1]).weekYear()                                                   // текущий год

    const endDate = dayjs(date[1]).valueOf()                                                       // последний день текущей недели
    const weeksArray: string[] = [`${numberWeek}_${currentYear}`]                                  // добавляем текущую неделю в массив
    let res = endDate

    for (let i = weekCount - 1; i > 0; i--) {                                                       // с каждой итерацией отнимаем неделю и складываем в массив с неделями
        res = res - 604800000
        const week = dayjs(res).week()
        const year = dayjs(res).weekYear()
        weeksArray.unshift(`${week}_${year}`)
    }

    useEffect(() => {
        dispatch(fetchParamNewsTC())
        dispatch(fetchDataNewsTC({from_date: date[0], to_date: date[1], category: checkedCategories, spm: category === 'marketplaces' ? checkedMps : checkedSpm}))
    }, [date, checkedCategories, checkedSpm, checkedMps, page, category]);

    const newsCategories: string[] = []     // все категории в новостях

    news.forEach((el) => {
        if (!newsCategories.includes(el.category)) {
            newsCategories.push(el.category)
        }
    })

    const limitWeek = 5                      // хардкод количества недель для отображения
    const columnWidth = window.innerWidth <= 1440 ? window.innerWidth/limitWeek : 1440/limitWeek  // ширина столбцов
    const dataTables = newsCategories.map((cat) => {        // данные для 1 категории (1 категория = 1 таблица)

        const columns: any = weeksArray.map((el, index) => (     // формируем колонки по количеству недель
            {
                key: index,
                title: formatWeekToISODate(el),
                dataIndex: el,
                width: 212,
            }
        ))
        const platformsNews: string[] = []                                          // количество магазинов в каждой неделе
        news.filter((el) => el.category === cat).forEach((el) => {
            if (!platformsNews.includes(el.spm)) {
                platformsNews.push(el.spm)
            }
        })
        !columns.find((el: any) => el.key === -1) && columns.unshift({   // find из-за двойного рендера (пофиксить), добавляем общую колонку для категории
            key: -1,
            title: '',
            dataIndex: 'date',
            width: 20,
            render: (_: any, __: any, index: any) => ({
                children: index === 0 ? <div style={{
                        position: "sticky",
                        top: 70,
                        transform: "rotate(180deg)", // Поворот текста
                        writingMode: "vertical-rl", // Вертикальное направление текста
                        textAlign: "center", // Центровка текста
                        maxWidth: "20px", // Установка ширины колонки
                        whiteSpace: "nowrap", // Запрет переноса текста
                        overflow: "hidden", // Обрезка, если текст не помещается
                    }}>{cat}</div> : null,
                    props: {rowSpan: index === 0 ? platformsNews.length*2 : 0},
                }),
            })
            let dataSource: any[] = platformsNews.map((pl) => {  // формируем объекты для данных таблицы, где ключ - неделя, значение - пустой массив
                let res = {key: pl}

                columns.forEach((el: any) => {
                    if (el.dataIndex !== 'date') {
                        res = {...res, [el.dataIndex]: []}
                    }
                })
                return res
            })

            const result = dataSource.map((data) => {  // раскидываем новости по соответствующим массивам
                news.forEach((el) => {
                    if (el.spm === data.key && el.category === cat && checkedCategories.includes(el.category)) {
                        data = {...data, [el.num_week]: data[el.num_week] ? [...data[el.num_week], {get_date: el.get_date, title: el.title, content: el.content, links: el.links}] : []}
                    }
                })
                return data
            })

            const data = result.map((el) => {
            Object.keys(el).forEach((item, index) => {
                if (item !== 'key') {
                    return el[item] = <div key={index} style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        {el[item].map((i: any, ind: number) => {
                            const color = marketPlacesColors.concat(superMarketsColors).find((spm) => spm.name === el.key)
                            return  <div key={ind}>
                                <ItemNews data={i} color={color?.color} platform={el.key}/>
                            </div>
                        })}
                    </div>
                }
            })
            return el
        })
        return {
            columns,
            dataSource: data
        }
    })

    function CustomRow(props: any) {
        const color = marketPlacesColors.concat(superMarketsColors).find((spm) => spm.name === props['data-row-key'])
            return (
                <Tooltip title={props['data-row-key']} placement="left" color={color?.color} overlayInnerStyle={{color: color?.color === lightColor ? '#000' : '#FFF'}}>
                    <tr {...props} />
                </Tooltip>
            );
    } // для tooltip

    return (
        <div className={styles.main}>
            <div className={styles.title}>Новости</div>
            <div className={styles.tableHead}>
                {weeksArray.map((el, index) => {
                    return <div key={index} className={styles.tableItem} style={{width: '100%'}}>
                        {formatWeekToISODate(el)}
                    </div>
                })}
            </div>
            <div>
                 {dataTables.length ? dataTables.map((table, index) => <div key={index} style={{borderBottom: '5px solid white'}} ><Table
                    columns={table.columns}
                    dataSource={table.dataSource}
                    pagination={false}
                    showHeader={false}
                    bordered={false}
                    size={'small'}
                    components={{
                        body: {
                            row: CustomRow
                        }
                    }}
                />
            </div>) : <EmptyComponent/>}
            </div>

        </div>
    );
};

