import React from 'react';
import styles from "./newsOptions.module.sass";
import {Button, Checkbox, DatePicker} from "antd";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {setNewsCategoriesAC, setNewsDateAC, setNewsMpsAC, setNewsSpmAC} from "../../../redux/newsReducer";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {
    selectCategoriesNews,
    selectCategory,
    selectDateNews, selectMpsNews,
    selectParamNews,
    selectSpmNews
} from "../../../common/selectors";
import dayjs from "dayjs";
import {Buttons} from "../../options/buttons";



export const NewsOptions = () => {

    const dispatch = useAppDispatch()
    const { RangePicker } = DatePicker

    const checkedCategories = useAppSelector(selectCategoriesNews)
    const checkedMps = useAppSelector(selectMpsNews)
    const checkedSpm = useAppSelector(selectSpmNews)
    const param = useAppSelector(selectParamNews)
    const globCategory = useAppSelector(selectCategory)
    const date = useAppSelector(selectDateNews)

    const onChangeCategoriesHandler = (categories: CheckboxValueType[]) => {
        dispatch(setNewsCategoriesAC(categories))
    }
    const onChangeSpmHandler = (spm: CheckboxValueType[]) => {
        if (globCategory === 'marketplaces') {
            dispatch(setNewsMpsAC(spm))
        }
        if (globCategory === 'supermarkets') {
            dispatch(setNewsSpmAC(spm))
        }

    }

    const optionsCategories = param.category?.length ? param.category.map(el => ({label: el, value: el})) : [{label: '', value: ''}]

    const getOptionsPlatform = () => {
        if (globCategory === 'marketplaces') {
            return param.mps.map(el => ({label: el, value: el}))
        }
        if (globCategory === 'supermarkets') {
            return param.spm.map(el => ({label: el, value: el}))
        }  else {
            return [{label: '', value: ''}]
        }
    }

    //const optionsSpm = param.spm?.length ? param.spm.map(el => ({label: el, value: el})) : [{label: '', value: ''}]

    const optionsPlatform = getOptionsPlatform()

    const changeDate = (period: number) => {
        dispatch(setNewsDateAC([dayjs(dayjs(date[0]).valueOf() + period).format('YYYY-MM-DD'), dayjs(dayjs(date[1]).valueOf() + period).format('YYYY-MM-DD')]))
    }

    return (
        <div className={styles.settings}>
            <Buttons place={'news'}/>
            <div className={styles.checkBoxes}>
                <div className={styles.title}>Категории</div>
                <div>
                    {<Checkbox.Group
                        onChange={onChangeCategoriesHandler}
                        value={checkedCategories}
                    >
                        <div className={styles.items}>
                            {optionsCategories.map((el, index) => <Checkbox key={index}
                                                                            value={el.value}>{el.label}</Checkbox>)}
                        </div>
                    </Checkbox.Group>}
                </div>
            </div>
            <div className={styles.checkBoxes}>
                <div className={styles.title}>{globCategory === 'marketplaces' ? 'Маркетплейсы' : 'Супермаркеты'}</div>
                <div className={styles.items}>
                    <Checkbox.Group
                        onChange={onChangeSpmHandler}
                        options={optionsPlatform}
                        value={globCategory === 'marketplaces' ? checkedMps : checkedSpm}
                    />
                </div>
            </div>
            <div>Период</div>
            <div style={{fontSize: 22}}>{`${date[0]} / ${date[1]}`}</div>
            <div>По неделям</div>
            <div style={{display: 'flex', gap: 20}}>
                <Button type={'primary'}
                    onClick={() => changeDate(-604800000)}>Пред. неделя</Button>
                <Button type={'primary'}
                    onClick={() => changeDate(604800000)}>Селед. неделя</Button>
            </div>
            <div>По периодам</div>
            <div style={{display: 'flex', gap: 20}}>
                <Button type={'primary'}
                    onClick={() => changeDate(-3024000000)}>Пред. период</Button>
                <Button type={'primary'}
                    onClick={() => changeDate(3024000000)}>След. период</Button>
            </div>
        </div>
    );
};