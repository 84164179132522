import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import styles from './carousel.module.sass'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {DataBannersType} from "../../../common/types";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {clearTableErrorAC, fetchBanners, postBanners} from "../../../redux/tableReducer";
import {
    bannerStatus,
    errorBanner,
    loadingBanners,
    selectBanner,
    selectParams,
    setError
} from "../../../common/selectors";
import {Spinner} from "../../../common/spin";
import {Button} from "antd";
import axios from "axios";
import {bannerApi} from "../../../api/bannerApi";
import {VerticalAlignBottomOutlined} from "@ant-design/icons";


type PropsType = {
    data: {urls: string[]}
    callBack: (current: number) => void
    slide: number
    open: boolean
}

export const Carousel = ({data, callBack, slide, open}: PropsType) => {

    const dispatch = useAppDispatch()

    const banner = useAppSelector(selectBanner)
    const loading = useAppSelector(loadingBanners)
    const status = useAppSelector(bannerStatus)
    const params = useAppSelector(selectParams)
    const error = useAppSelector(errorBanner)


    const refUpload = useRef<HTMLInputElement>(null)


   /* const [dataSlides, setDataSlides] = useState<{ urls: string[] }>({urls: []});
    const [oldSlide, setOldSlide] = useState(0);
    //const [activeSlide, setActiveSlide] = useState(0);
    const [activeSlide2, setActiveSlide2] = useState(0);*/


   /* useEffect(() => {
        dispatch(fetchBanners({name_sale: 'Акции и скидки', spm: 'ozon',  from_date: '2023-09-10', to_date: '2023-12-31', from_date_sale: '2023-09-30', to_date_sale: '2023-12-18'}))
        console.log('fetch')
    }, [post]);*/

    const [file, setFile] = useState<File|null>(null)
    const [index, setIndex] = useState<number>(-1)

    let sliderRef: any = useRef(null);

    const formData = new FormData()

    useEffect(()  => {
        //fetchBanners(params)
        if (sliderRef.current?.slickGoTo)
            sliderRef.current.slickGoTo(slide);
    }, [slide, status]);

    const settings = {
        dots: true,
        adaptiveHeight: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: slide,
        beforeChange: (current:number, next:number) => {
            /*setOldSlide(current);
            callBack(next);*/
            setIndex(next)
        },
        //afterChange: (current:number) => setActiveSlide2(current)
    }

    const onClickHandler = (e:any) => {
        console.log(e)
    }

    const banners = data.urls.length&&data.urls.map((el, index) => <div key={index} onChange={onClickHandler} className={styles.banners} /*onClick={() => onClickHandler(el)}*/><img
                                                                             src={`http://94.103.89.39/${el}`}
                                                                             className={styles.item}
                                                                             alt="item"/></div>)
    const uploadHandler = () => {
        refUpload.current&&refUpload.current.click()
    }

    const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
        setFile(event.target.files![0]!)



    }

    if(file) {
        formData.append('image', file)
        formData.append('file_url', `http://94.103.89.39/${ banner.urls[index]}`)
        dispatch(postBanners(formData))
    }

    return (
        <div className={styles.main}>

            {!error ?
                (
                    loading === 'loading' ? <Spinner/> :
                        <div>

                            <Button type={"link"} icon={<VerticalAlignBottomOutlined/>} onClick={uploadHandler}
                                    className={styles.button}/>
                            <input ref={refUpload} type="file" onChange={handleChange}
                                   multiple={true} style={{display: 'none'}}/>
                            <Slider {...settings} ref={sliderRef}>
                                {banners}
                            </Slider>
                        </div>
                ) :
                <div style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                    flexDirection: 'column', alignItems: 'center'
                }}>
                    <div>Недостаточно прав для совершения данного действия</div>
                    <Button onClick={() => dispatch(clearTableErrorAC(''))} type={'primary'}
                            style={{width: '50%'}}>OK</Button>
                </div>
            }
        </div>
    );
};

