import axios from "axios";
import {HOST} from "../common/constants";

const instance = axios.create({
    baseURL: HOST,
})


instance.interceptors.request.use(
    request => {

        request.headers.Authorization = `JWT ${localStorage.getItem('jwt')}`
        request.headers['Content-Type'] = 'multipart/form-data'

        return request
    },
    error => {
        console.log(error)
    }
);

export const newsApi = {
    getData(from_date: string, to_date: string, category: string[], spm: string[]){
        return instance.get(`news/?from_date=${from_date}&to_date=${to_date}&category=${category}&spm=${spm}`)
    },
    getNewsParam(){
        return instance.get('news-param/')
    }

}