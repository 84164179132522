import React from 'react';
import styles from './contentBlock.module.sass';
import {dataType, InfoPoint} from "../../../../common/types";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import {weekAnnotations} from "../../../../common/optionsForCharts/weekAnnotations";
import {formatDateForCategories, getPeriod, periodLineChart, valueOf} from "../../../../common/optionsForDate";
import {useAppSelector} from "../../../../redux/store";
import {selectEndDatePoint, selectInterval, selectStartDatePoint} from "../../../../common/selectors";
import {offsetXLabels} from "../../../../common/optionsForCharts/visualSettings";
import {formatGrade} from "../../../../common/optionsForCategories/table";
import {PointTable} from "./pointTable";

dayjs.locale('ru')

export type ContentBlockPropsType = {
    data: InfoPoint | null
}

export const ContentBlock = ({data}: ContentBlockPropsType) => {
    const startDate = useAppSelector(selectStartDatePoint)
    const endDate = useAppSelector(selectEndDatePoint)
    const interval = useAppSelector(selectInterval)

    if (!data || !data.sales || data.sales.length === 0) {
        return <div>No data available</div>;
    }

    const series: dataType[] = [
        {name: 'Продано товаров', type: 'line', data: []},
        {name: 'Объем продаж', type: 'line', data: []},
    ];

    const formTime = valueOf([startDate, endDate])
    const period = getPeriod([startDate, endDate])
    const tickAmountTime = +(period / 86400000).toFixed()
    const tickAmountWeek = +(period / 604800000).toFixed()
    const offsetXLab = offsetXLabels(period)


    const periodDay = dayjs(endDate).valueOf() - dayjs(startDate).valueOf() + 80000000
    const tickAmountTimeForDay = +(periodDay / 86400000).toFixed()
    const tickAmountWeekForDay = +(periodDay / 604800000).toFixed()

    const periodMonth = dayjs(endDate).valueOf() - dayjs(startDate).valueOf()
    const tickAmountTimeForMonth = interval === 2 ? Math.ceil(+(periodMonth / 2629743000)) :  +(periodMonth / 2629743000).toFixed()
    const tickAmountWeekForMonth = +(periodMonth / 604800000).toFixed()
    const getTickAmount = () => {
        if (interval === 0) {
            return [tickAmountTimeForDay, tickAmountWeekForDay]
        }
        if (interval === 1) {
            return [tickAmountTime, tickAmountWeek]
        }
        return [tickAmountTimeForMonth, tickAmountWeekForMonth]

    }

    const tickAmount = getTickAmount()
    const formatterXaxisLabels = (value: any) => {
        if (interval === 1) {
            return dayjs(value).format('DD')
        }
        if (interval === 0) {
            if (periodDay < 1500000000) {
                return dayjs(value).format('DD MMM')
            }
            return dayjs(value).format('DD-MM')
        }
        return dayjs(value + 60000000).format('MMMM YYYY')


    }

    const showAxisTicks = () => {
        if (interval === 2) {
            return true
        }
        if (period < 6200000000) {
            return true
        }
        return false
    }

    const options = {
        chart: {
            type: 'line' as 'line',
            height: 350,
            stacked: false,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: true,
            background: {
                borderRadius: 10
            },
            formatter: function (val: any, opts: any) {
                return formatGrade(val)
            },
        },
        annotations: {
            xaxis: interval === 1 ? weekAnnotations(formTime, interval ? period : periodDay)[0] : [],
        },
        legend: {
            position: 'top' as 'top',
            labels: {
                colors: '#FFFFFF'
            },
        },
        grid: {
            borderColor: '#202020',
            padding: {
                left: 30,
                right: 30
            },
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        colors: ['#2c3ae7', '#08A652'],
        xaxis: {
            type: 'numeric' as 'numeric',
            min: interval === 1 ? periodLineChart([startDate, endDate])[0] : dayjs(startDate).valueOf(),
            max: interval === 1 ? periodLineChart([startDate, endDate])[1] : dayjs(endDate).valueOf() + (interval === 2 ? 2629743000 : 90000000), // + 1 день + 3 часа
            tickAmount: window.innerWidth > 1000 ? (period > 5500000000 && interval !== 2 ? tickAmount[1] : tickAmount[0]) : tickAmount[1],
            tickPlacement: 'between',
            axisBorder: {
                show: true,
                color: '#F5F5F5',
                height: 3,
                width: '120%',
                offsetX: -100,
                offsetY: -1
            },
            axisTicks: {
                show: showAxisTicks(),
                offsetY: 20,
                height: 10,
            },
            labels: {
                show: true,

                formatter: function (value: any, timestamp: any, opts: any) {
                    return window.innerWidth > 1000 ?
                        (period > 5500000000 && interval !== 2 ? formatDateForCategories(value) :
                            formatterXaxisLabels(value)) : formatDateForCategories(value)
                },
                offsetX: interval === 1 ? offsetXLab : (!interval ? (periodDay < 1314871000 ? 20 : 10) : 100),
                offsetY: 3,
                style: {
                    colors: 'white',
                    fontWeight: 14,
                    fontSize: '12',
                },


            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: [
            {
                seriesName: 'Продано товаров',
                labels: {
                    show: false,
                    formatter: (value: any) => value.toFixed(0),
                },
                min: -1000
            },
            {
                seriesName: 'Объем продаж',
                labels: {
                    show: false,
                    formatter: (value: any) => value.toFixed(0),
                },

                max: interval ? (interval === 2 ? 65000000 : 21000000) : 4500000,
                min: interval === 2 ? 3000000 : 0
            },
        ],
        tooltip: {
            enabled: true,
            theme: 'dark',
            x: {
                formatter: interval === 1 ? function (value: any) {
                    return formatDateForCategories(value)
                } : undefined
            },
            y: {
                formatter: function (value: any) {
                    return formatGrade(value)
                }
            }
        }
    };


    data.sales.forEach((el, index) => {
        if (interval === 1) {
            if (index === data.sales.length - 1) {
                series[0].data.push([(dayjs(el.startDate).valueOf() + 302400000), el.salesCount]);
                series[1].data.push([(dayjs(el.startDate).valueOf() + 302400000), el.salesSum]);
            } else {
                series[0].data.push([(dayjs(el.endDate).valueOf() - 302400000), el.salesCount]);
                series[1].data.push([(dayjs(el.endDate).valueOf() - 302400000), el.salesSum]);
            }
        }
        if (interval === 2) {
            if (index === data.sales.length - 1) {
                series[0].data.push([(dayjs(el.startDate).valueOf() + 1314872000), el.salesCount]);
                series[1].data.push([(dayjs(el.startDate).valueOf() + 1314872000), el.salesSum]);
            } else {
                series[0].data.push([(dayjs(el.endDate).valueOf() - 1314872000), el.salesCount]);
                series[1].data.push([(dayjs(el.endDate).valueOf() - 1314872000), el.salesSum]);
            }
            /*series[0].data.push([(dayjs(el.endDate).valueOf() - 1314872000), el.salesCount]);
            series[1].data.push([(dayjs(el.endDate).valueOf() - 1314872000), el.salesSum]);*/
        }
        if (interval === 0) {
            series[0].data.push([(dayjs(el.endDate).valueOf() - 54000000), el.salesCount]);
            series[1].data.push([(dayjs(el.endDate).valueOf() - 54000000), el.salesSum]);
        }
    });

    return (
        <div>
            <div style={{marginBottom: 20}}>
                <PointTable data={data} interval={interval}/>
            </div>
            <ReactApexChart
                series={series}
                type="line"
                options={options}
                height={700}
            />
        </div>
    );
};


