import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {
    selectCategory,
    selectCountProd,
    selectFilter,
    selectFilterTitle,
    selectFilterTitleSpm,
    selectTableContent,
    selectTableContentForSpm,
    selectTime,
    selectZoomedTime
} from "../../../common/selectors";
import {DataTableResType, DataTableSpmResType} from "../../../common/types";
import dayjs from "dayjs";
import {zoomAC} from "../../../redux/optionsReducer";
import {formatArrayTime, periodLineChart, valueOf} from "../../../common/optionsForDate";
import ReactApexChart from "react-apexcharts";
import {EmptyComponent} from "../../../common/empty";
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import {wordsForLighting, wordsForNotLighting} from "../../../common/optionsForTimeLineChartAccent";

dayjs.extend(utc)
dayjs.extend(timezone)

export const TimeLineChart1 = () => {

    const category = useAppSelector(selectCategory)
    const tableContent = useAppSelector(selectTableContent)
    const tableContentSpm = useAppSelector(selectTableContentForSpm)
    const time = useAppSelector(selectTime)
    const filter = useAppSelector(selectFilter)
    const filterTitleMps = useAppSelector(selectFilterTitle)
    const filterTitleSpm = useAppSelector(selectFilterTitleSpm)
    const countProd = useAppSelector(selectCountProd)
    const zoom = useAppSelector(selectZoomedTime)
    const dispatch = useAppDispatch()
    const zoomedTime = useAppSelector(selectZoomedTime)

    let data1: DataTableResType[] | DataTableSpmResType = []

    if (category === 'marketplaces') {
        data1 = tableContent.map((el) => {
            return  {...el, to_date: dayjs(el.to_date).valueOf() + 86399000, get_date_row: dayjs(el.get_date_row).valueOf()}
        })
        /*data1 = data
            .filter((el) => {
                if (!countProd && el.spm !== 'AliExpress') {
                    return  el.count_products! >= 2000000 || el.is_sec_field

                }
                if (!countProd && el.spm === 'AliExpress') {
                    return  el.count_products! >= 20000 || /!*el.name_sale.toLowerCase().includes('электроника') || el.name_sale.toLowerCase().includes('техника')*!/ el.is_sec_field
                }
                return el
            })*/
    }
    if (category === 'supermarkets') {
        data1 = tableContentSpm.map((el) => {
            return  {...el, to_date: dayjs(el.to_date).valueOf() + 86399000, get_date_row: dayjs(el.get_date_row).valueOf()}
        })
    }

    const namesPlaces: string[] = Array.from(new Set(data1.map((el) => el.spm)))
    const separateData: {name: string, data: DataTableResType[]}[] = namesPlaces.map((el) => {
        return {
            name: el,
            data: []
        }
    })

    data1.forEach((el) => {
        separateData.forEach((item) => {
            return el.spm === item.name ? item.data.push(el) : item
        })
    })
    const formatMandK = (value: number) => {
        if (value! <= 999999 && value! !== 0) {
            return (value!/1000)?.toFixed(1) + 'K'
        }
        if (value! > 999999 && value! !== 0) {
            return (value!/1000000)?.toFixed(1) + 'M'
        }
        return 0
    }
    const getData = () => {
        let dataForCharts:any = []
        let dataPlc: any[] = []
        separateData.length&&separateData.map((el) => {
            if (el.data.length === 1) {
                dataForCharts.push([el.data])
            } else {

                el.data.sort((a,b) => b.get_date_row.toString().localeCompare(a.get_date_row.toString()))
                //el.data.sort((a,b) => b.to_date.toString().localeCompare(a.to_date.toString()))

                el.data.sort((a,b) => a.get_date_row > b.to_date ? 1: -1)

                //el.data.sort((a,b) => (dayjs(b.to_date).valueOf() - dayjs(b.get_date_row).valueOf()) > (dayjs(a.to_date).valueOf() - dayjs(a.get_date_row).valueOf()) ? 1: -1)
                let result: any = []
                const func: any = () => {
                    let a = el.data.splice(0, 1)
                    result.push(a)
                    for (let i = 0; i < el.data.length-1; i++) {
                        el.data[i].get_date_row >= result[result.length - 1][result[result.length - 1].length - 1].to_date && result[result.length - 1].push(el.data[i]) && el.data.splice(i, 1)
                    }
                    if (el.data.length > 0) {
                        func()
                    } else {
                        return
                    }
                    return result
                }
                const res = func()
                dataForCharts.push(res)
            }

        })

        const getColor = (item: DataTableResType) => {
            const hasLightingWords = wordsForLighting.some(word =>
                item.acronym?.toLowerCase().includes(word) ||
                item.name_sale.toLowerCase().includes(word)
            );
            const hasNotLightingWords = wordsForNotLighting.some(word =>
                item.acronym?.toLowerCase().includes(word) ||
                item.name_sale.toLowerCase().includes(word) || item.spm === 'Самокат'
            );
            const hasNumbers = /\d/.test(item.average_percent.toString());

            if (hasLightingWords && hasNotLightingWords) {
                return item.colors;
            } else if (hasLightingWords) {
                return item.altColor;
            } else if (hasNotLightingWords) {
                return item.colors;
            } else if (!hasNumbers) {
                return item.altColor;
            } else {
                return item.colors;
            }
        };

        dataForCharts&&dataForCharts.forEach((data:any) => {
            data&&data.forEach((el:DataTableResType[], index: number) => {
                el.forEach((item) => dataPlc.push(category==='marketplaces'?
                    {
                        name: item.acronym ? item.acronym : item.name_sale,
                        color: !item.luminance ? item.colors : item.altColor,
                        place: item.spm,
                        data: [
                            {
                                x: `${item.spm}${index}`,
                                y: [
                                    item.get_date_row < dayjs(zoomedTime[0]).day(1).valueOf() ? dayjs(zoomedTime[0]).day(1).valueOf() + 10800000 : +item.get_date_row + 10800000,
                                    item.to_date > dayjs(zoomedTime[1]).day(7).valueOf()+86399000 ? dayjs(zoomedTime[1]).day(7).valueOf()+86399000 + 1080000 : +item.to_date + 10800000 /*+ 86399000*/,
                                ],
                                name: item.name_sale,
                                color: item.colors,
                                place: item.spm,
                                id: item.id,
                                count_categories: item.count_categories,
                                count_products: formatMandK(item.count_products!),
                                average_percent: item.average_percent,
                                get_date_row: item.get_date_row,
                                to_date: item.to_date,
                                acronym: item.acronym,
                                sec_info: item.sec_info

                            }
                        ]
                    }:
                    {
                        name: item.name_sale,
                        color: !item.luminance ? item.colors : item.altColor,
                        place: item.spm,
                        data: [
                            {
                                x: `${item.spm}${index}`,
                                y: [
                                    item.get_date_row < dayjs(zoomedTime[0]).day(1).valueOf() ? dayjs(zoomedTime[0]).day(1).valueOf() + 10800000 : +item.get_date_row + 10800000,
                                    item.to_date > dayjs(zoomedTime[1]).day(7).valueOf()+86399000 ? dayjs(zoomedTime[1]).day(7).valueOf()+86399000 + 1080000 : +item.to_date + 10800000 /*+ 86399000*/,
                                ],
                                name: item.name_sale,
                                color: item.colors,
                                place: item.spm,
                                id: item.id,
                                count_categories: item.count_categories,
                                average_percent: item.average_percent,
                                get_date_row: item.get_date_row,
                                to_date: item.to_date,

                            }
                        ]
                    }
                ))
            })
        })
        return dataPlc
    }

    const dataTotal = getData()
        .filter((el) => filterTitleMps.includes(el.place))
    const formTime = valueOf(zoom)
    const countStringCharts = Array.from(new Set(dataTotal.map((el) => el.data[0].x))).length

    //_________________________________________________________________________________________________
    const options = {
        chart: {
            //height: '1000px',
            //width: '100%',
            type: 'rangeBar' as const,
            foreColor: '#F5F5F5',
            toolbar: {
                show: false,
                offsetY: -10
            },
            offsetX: 0,
            zoom: {
                enabled: false
            },
            events: {
                zoomed: (chartContext: any, {xaxis, yaxis}: any) => {
                    return dispatch(zoomAC(xaxis.min?formatArrayTime(xaxis):zoomedTime))
                },
                /*beforeResetZoom: function (chartContext: any, opts: any) {
                    return dispatch(zoomAC(time))
                }*/
            },


        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '80%',
                rangeBarGroupRows: true,
                dataLabels: {
                    position: 'bottom',
                    hideOverflowingLabels: true,
                }
            }
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start' as const,
            offsetX: -40,
            formatter: (val: any, { seriesIndex, dataPointIndex, w }: any) => {
                let viewIndex: any = []
                w.globals.initialSeries.forEach((el: any, index: number) => {
                    if (!(el.data[0].y[1] < formTime[0] || el.data[0].y[0] > formTime[1])){
                        viewIndex.push(index)
                    }
                })
                let label = w.globals.seriesNames[seriesIndex]
                return  viewIndex.includes(seriesIndex) ? label : ''
            },
            style: {
                fontSize: '12px',
            }
        },
        xaxis: {
            show: false,
            min: periodLineChart(zoom)[0], // + 3 часа
            max: periodLineChart(zoom)[1], // + 1 день + 3 часа
            type: 'datetime' as const,
            position: 'top',
            labels: {
                show: false,
                style: {
                    colors: 'white',


                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false,

        },
        stroke: {
            width: 1,
            colors: ['gray'],
        },
        grid: {
            show: true,
            borderColor: '#202020',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: -15
            },
        },
        fill: {
            type: 'solid',
            opacity: 0.6
        },
        legend: {
            show: false
        },
        tooltip: {
            theme: 'dark',
            fillSeriesColor: true,
            custom: function({series, seriesIndex, dataPointIndex, w}: any) {
                const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                if (category === 'marketplaces') {
                    return `<div class="toolTip">` +
                        '<div class="toolTip__head">' + data.name + '</div>' +
                        '<div class="toolTip__list">'+
                        '<div style="color:'+data.color+'">'+ data.place +'</div>'+
                        '<div>'+'<span class="toolTip__item">'+'Количество товаров: '+'</span>'+data.count_products+'</div>'+
                        '<div>'+'<span class="toolTip__item">'+'Анонсируемая скидка: '+'</span>'+data.average_percent+'</div>'+
                        '<div>'+'<span class="toolTip__item">'+'Дата начала: '+'</span>'+dayjs(data.get_date_row).format('YYYY-MM-DD')+'</div>'+
                        '<div>'+'<span class="toolTip__item">'+'Дата завершения: '+'</span>'+dayjs(data.to_date).format('YYYY-MM-DD')+'</div>'+
                        '<div>'+'<span class="toolTip__item">'+'Описание: '+'</span>'+data.sec_info+'</div>'+
                        '</div>'+
                        '<div/>'
                } else {
                    return `<div class="toolTip">` +
                        '<div class="toolTip__head">' + data.name + '</div>' +
                        '<div class="toolTip__list">'+
                        '<div style="color:'+data.color+'">'+ data.place +'</div>'+
                        '<div>'+'<span class="toolTip__item">'+'Категории(SKU): '+'</span>'+data.count_categories+'</div>'+
                        '<div>'+'<span class="toolTip__item">'+'Размер скидки: '+'</span>'+data.average_percent+'</div>'+
                        '<div>'+'<span class="toolTip__item">'+'Дата начала: '+'</span>'+dayjs(data.get_date_row).format('YYYY-MM-DD')+'</div>'+
                        '<div>'+'<span class="toolTip__item">'+'Дата завершения: '+'</span>'+dayjs(data.to_date).format('YYYY-MM-DD')+'</div>'+
                        '</div>'+
                        '<div/>'
                }

            },
            /*fixed: {
                enabled: true,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },*/
        },

    }
    return (

        <div>
            {dataTotal.length?<ReactApexChart options={options}
                                              series={dataTotal}
                                              type="rangeBar"
                                              height={20*countStringCharts + 50}
                                              width={1000}
                                              style={{backgroundColor: '#202020', textColor: 'white'}}/>:<EmptyComponent/>}
        </div>
    );
};
