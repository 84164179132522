import React, {useEffect, useState} from 'react';
import styles from './newsItem.module.sass'
import cn from "classnames";
import {useAppSelector} from "../../../redux/store";
import {selectCollapse} from "../../../common/selectors";

type NewsItemPropsType = {
    data: {
        get_date: string,
        title: string,
        content: string,
        links: string
    }
    color: string | undefined

}

export const NewsItem = ({data, color}: NewsItemPropsType) => {

    const {get_date, title, content, links} = data

    const collapse = useAppSelector(selectCollapse)

    const [hidden, setHidden] = useState(true)

    useEffect(() => {
        setHidden(collapse)
    }, [collapse]);

    const onClickHandler = () => {
        setHidden(!hidden)
    }

    return (
        <div className={cn(styles.main, hidden ? styles.hidden : styles.expanded)} style={{backgroundColor: color ? color : '#000', color: color === '#feb302' ? '#OOOOOO' : '#FFF'}} onClick={onClickHandler}>
            <div className={styles.date}>{get_date}</div>
            <div className={styles.title}>{title}</div>
            <div className={styles.content}>{content}</div>
            <a href={links} target="_blank" className={styles.links}>источник</a>
        </div>
    );
};

