import React from 'react';
import {Popover} from "antd";
import styles from './itemNews.module.sass'
import {lightColor} from "../../../common/optionsForColors";


type NewsItemPropsType = {
    data: {
        get_date: string,
        title: string,
        content: string,
        links: string
    }
    color: string | undefined
    platform: string

}

export const ItemNews = ({data, color, platform}: NewsItemPropsType) => {

    const {get_date, title, content, links} = data

    const contentPopover = <div className={styles.popoverContent}>
        <div style={{color}}>{platform}</div>
        <div className={styles.title}>{title}</div>
        <div>{content}</div>
        <a href={links} target="_blank">источник</a>
    </div>

    return (
        <Popover placement="right" title={<div style={{color: '#FFFFFF'}}>{get_date}</div>} content={contentPopover} overlayStyle={{color: '#FFFFFF'}} color={'#202020'}>
            <div className={styles.item}  style={{backgroundColor: color ? color : '#000', color: color === '#feb302' ? '#000' : '#FFF'}}>
                <div className={styles.itemText}>{title}</div>
            </div>
        </Popover>
    );
};

