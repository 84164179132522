import {dataType, YaxisType} from "../types";
import {lightColor, marketPlacesColors, superMarketsColors, textColor} from "../optionsForColors";
import {
    CATALOG_FACTOR,
    DELTA,
    FONT_SIZE_DATA_LABELS,
    HEIGHT,
    OZ_AKB_FACTOR,
    SAM_AKB_FACTOR,
    WB_AKB_FACTOR,
    WB_AKB_FACTOR_MIN
} from "../constants";
import {formatGrade} from "../optionsForCategories/table";
import {formatDateForCategories} from "../optionsForDate";
import dayjs from "dayjs";
import {minValueData, offsetXLabels} from "./visualSettings";


export const weekAnnotations = (time: number[], period: number, between?: number) => {
    const week = 604800000

    const controlDate = 1672617600000
    let startWeekForChart = 0
    let start = new Date(time[0]).valueOf()
    let end = new Date(time[1]).valueOf() + (between ? between : 604800000)
    for (let i = controlDate; i <= start; i += week) {
        startWeekForChart = i
    }
    let weekArray: any[] = []
    for (let i = startWeekForChart; i <= end; i += week) {
        weekArray.push(i)
    }
    let arrOfObjects: any[] = []
    let weekPoints: any[] = []
    /*const setIndex = () => {
        if (chart === 'Рынок, темпы' || chart === 'Рынок, руб') {
            return 6
        }
        if (chart === 'АКБ %' || chart === 'АКБ') {
            return 0
        }

        if (chart === 'Каталог') {
            return 0
        }
        return 1
    }
    let yAxisIndex = setIndex()*/
    const offsetXLab = () => {
        let day = +(period/86400000).toFixed()
        let dayPx = 1040/day
        return +(dayPx * 4.5).toFixed()
    }
    weekArray.map((el) => {
        /*weekPoints.push({
            x: el + 604800000/2,
            y: 0,
            yAxisIndex,
            marker: {
                size: 0
            },
            label: {
                offsetY: 20,
                text: formatDateForCategories(el),
                textAnchor: 'middle',
                borderRadius: 10,
                borderWidth: 0,
                style: {
                    background: 'none',
                    color:'#F5F5F5',
                    fontSize: FONT_SIZE_DATA_LABELS,
                    fontWeight: '600',
                    padding: {
                        left: 5,
                        right: 5,
                        top: 0,
                        bottom: 2,
                    }
                }
            }
        })*/
         arrOfObjects.push({
                x: el,
                label: {
                    text: window.innerWidth>1000 ? (period > 5500000000 ? '' : formatDateForCategories(el)) : '',
                    orientation: weekArray.length < 15 ? 'horizontal' : 'vertical',
                    position: 'bottom',
                    offsetY: 12,
                    offsetX: offsetXLab(),
                    borderWidth: 0,
                    style: {
                        background: '#202020',
                        color: '#F5F5F5',
                        fontSize: 12
                    }
                }
            })
        },

    )
    return [arrOfObjects, weekArray, weekPoints]
}


export const weekAnnotationsForPrice = (time: number[]) => {
    const weekAnno = weekAnnotations(time, 0)[0]
    const res: any = []
    weekAnno.forEach((el) => {
        res.push({
            ...el, borderColor: 'rgba(32,32,32, 0)', label: {
                text: formatDateForCategories(el.x),
                orientation: 'horizontal',
                position: 'bottom',
                offsetY: 15,
                borderWidth: 0,
                style: {
                    background: '#202020',
                    color: '#F5F5F5',
                }

            }
        })
    })
    return res
}

export const setPointsForDataLabels = (data: dataType[],
                                       chart: string,
                                       category: string,
                                       yaxis: YaxisType[],
                                       changeData: boolean,
                                       clickAnno: number[],
                                       tableCharts?: string) => {
    let points: any = []                      // аннотации
    let indexY = 0                           // индекс оси Y
    let background: string = ''             // цвета
    let dateOfChart: number[] = []         // массив уникальных дат
    data.forEach((el) => {
        el.data.forEach((item) => !dateOfChart.includes(item[0]) ? dateOfChart.push(item[0]) : item)

    })
    const names = data.map(el => el.name)
    const indexMarket = names.indexOf('Рынок')
    const nameSOV = names.find((el, index) => el.includes('SOV'))
    const indexSOV = names.indexOf(nameSOV!)
    const nameOther = names.find((el) => el !== 'Рынок' && !el.includes('SOV'))
    const indexOther = names.indexOf(nameOther!)

    data.forEach((el, index) => {

            if ((chart === 'Рынок, темпы' || chart === 'Рынок, руб') && el.name === 'Рынок') {
                indexY = indexMarket
            }
            if ((chart === 'Рынок, темпы' || chart === 'Рынок, руб') && el.name.includes('SOV')) {
                indexY = indexSOV
            }
            if ((chart === 'Рынок, темпы' || chart === 'Рынок, руб') && el.name !== 'Рынок' && !el.name.includes('SOV')) {
                indexY = indexOther
            }
            if ((chart === 'Каталог' || chart === 'Каталог1') && el.type === 'line') {
                indexY = 0
            }
            if ((chart === 'Каталог' || chart === 'Каталог1') && el.type === undefined) {
                indexY = 5
            }
            if (chart === 'АКБ') {
                indexY = 0
            }

            if (category === 'marketplaces') {
                marketPlacesColors.find((i) => {
                    return i.name === el.name ? background = i.color : background = ''
                })
            }
            if (category === 'supermarkets') {
                superMarketsColors.find((i) => {
                    return i.name === el.name ? background = i.color : background = ''
                })
            }


            el.data.forEach((item) => {
                let text: number | string = ''
                if (chart === 'Рынок, темпы' || chart === 'Рынок, руб' || chart === 'АКБ') {
                    text = item[1].toString()
                }
                if (chart === 'Рынок, темпы') {
                    text = item[1].toString() + '%'
                }
                if ((chart === 'АКБ') && el.type === 'line') {
                    text = formatGrade(item[1])
                }
                if ((chart === 'Каталог' ) && el.type === 'line') {
                    text = (item[1]/1000000).toFixed(1)
                }
                if ((chart === 'Каталог1') && el.type === 'line') {
                    text =  formatGrade(item[1])
                }
                if ((chart === 'Каталог' || chart === 'Каталог1') && el.type === undefined) {
                    text = formatGrade(item[1].toFixed(2)) + '%'
                }
                if (chart === 'Данные 1 уровня') {
                    text = item[1].toString()
                }
                if (chart === 'АКБ' && el.name === 'Wildberries') {
                    text = formatGrade(item[1] + /*(changeData?WB_AKB_FACTOR:WB_AKB_FACTOR_MIN)*/ WB_AKB_FACTOR_MIN)
                }
                if (chart === 'АКБ' && el.name === 'Ozon' /*&& changeData*/) {
                    text = formatGrade(item[1])
                }
                if (chart === 'АКБ' && el.name === 'Самокат') {
                    text = formatGrade(item[1] + SAM_AKB_FACTOR)
                }
                if (chart === 'Каталог' && (el.name === 'Wildberries' || el.name === 'Ozon' || el.name === 'AliExpress') && el.type === 'line' && !tableCharts) {
                    text = ((item[1] + CATALOG_FACTOR)/1000000).toFixed(1)
                }
                points.push({
                    x: item[0],
                    y: item[1],
                    yAxisIndex: indexY,
                    marker: {
                        size: 0
                    },
                    label: {
                        offsetY: 0,
                        text: clickAnno.includes(index) ? '' : text,
                        textAnchor: 'middle',
                        borderRadius: 10,
                        borderWidth: .2,
                        style: {
                            background,
                            color: background === lightColor ? textColor : '#F5F5F5',
                            fontSize: FONT_SIZE_DATA_LABELS,
                            fontWeight: '600',
                            padding: {
                                left: 5,
                                right: 5,
                                top: 0,
                                bottom: 2,
                            }
                        }
                    }
                })
            })
        }
    )
    let yAxisMarket: any[] = []
    let yAxisOther: any[] = []
    let yAxisSOV: any[] = []

    dateOfChart.forEach((el) => yAxisOther.push([]))
    dateOfChart.forEach((el) => yAxisSOV.push([]))
    points.forEach((el: any) => {
        if (el.yAxisIndex === indexMarket && chart !== 'Данные 1 уровня') {
            yAxisMarket.push(el)
        }
        if (el.yAxisIndex === indexOther || (el.yAxisIndex === 0 && chart === 'АКБ')) {
            dateOfChart.forEach((date, index) => (
                date === el.x ? yAxisOther[index].push(el) : el
            ))
        }
        if (el.yAxisIndex === indexSOV || el.yAxisIndex === 5) {
            dateOfChart.forEach((date, index) => (
                date === el.x ? yAxisSOV[index].push(el) : el
            ))
        }
        if (el.yAxisIndex === 0 && chart === 'Данные 1 уровня') {
            dateOfChart.forEach((date, index) => (
                date === el.x ? yAxisOther[index].push(el) : el
            ))
        }
    })
    let deltaChart: number = 0
    let deltaSOV: number = 0
    yaxis.forEach((el) => {
        if (el.seriesName !== 'Рынок' && el.seriesName !== 'sell' && el.seriesName && !el.seriesName.includes('SOV')) {
            deltaChart = el.max! - el.min!
        }
        if (el.seriesName && (el.seriesName.includes('SOV') || el.seriesName === 'sell')) {
            deltaSOV = el.max! - el.min!
        }

    })

    if (chart === 'Рынок, темпы' || chart === 'Рынок, руб' || chart === 'Каталог' || chart === 'Каталог1' || chart === 'АКБ' || chart === 'Данные 1 уровня') {
        yAxisOther.forEach((point) => {
            point.sort((a: any, b: any) => a.y - b.y)
            for (let i: number = 1; i < point.length; i++) {
                let px = deltaChart / (chart === 'Данные 1 уровня' ? 167 : HEIGHT)
                let deltaUnit = px * DELTA
                let difference = point[i].y - point[i - 1].y
                if (difference < deltaUnit) {
                    point[i].label.offsetY -= DELTA - difference / (px)
                }
                if (point[i - 1].label.offsetY < 0 && difference < deltaUnit - point[i - 1].label.offsetY * px) {
                    point[i].label.offsetY += /*difference <= deltaUnit ? */point[i - 1].label.offsetY /*: point[i - 1].label.offsetY + 5*/
                }
            }
        })
        yAxisSOV.forEach((point) => {
            point.sort((a: any, b: any) => a.y - b.y)
            for (let i: number = 1; i < point.length; i++) {
                let px = deltaSOV / HEIGHT
                let deltaUnit = px * DELTA
                let difference = point[i].y - point[i - 1].y
                if (difference < deltaUnit) {
                    point[i].label.offsetY -= DELTA - difference / (px)
                }
                if (point[i - 1].label.offsetY < 0 && difference < deltaUnit - point[i - 1].label.offsetY * px) {
                    point[i].label.offsetY += /*difference <= deltaUnit ? */point[i - 1].label.offsetY /*: point[i - 1].label.offsetY + 5*/
                }
            }
        })
    }

    return yAxisMarket.concat(yAxisOther.reduce((acc, cur) => acc.concat(cur)), yAxisSOV.reduce((acc, cur) => acc.concat(cur)))

}

//__________________________________________________________________________________________________________

export const setPoints = (range: number[], data: any[]) => {
    let points: any = []
    range.unshift(0)
    //const dataForColumn = data.find((el) => el.name ==='Рынок млрд')
    const xForAnno = data[1].data.map((el: number[]) => el[0] - 304200000)
    xForAnno.forEach((el: any, index: number) => {
        if (range[index] >= 0 && index > 0) {
            points.push({
                x: el,
                y: 150,
                label: {
                    offsetY: 10,
                    text: range[index] + ' ▲',
                    borderRadius: 10,
                    style: {
                        color: "#fff",
                        background: "#08A652"
                    },
                }

            })
        }
        if (range[index] < 0 && index > 0) {
            points.push({
                x: el,
                y: 150,
                label: {
                    offsetY: 10,
                    text: range[index] + ' ▼',
                    borderRadius: 10,
                    style: {
                        color: "#fff",
                        background: "red"
                    },
                }

            })
        }

    })
    return points
}
