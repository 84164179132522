import React, {useEffect} from 'react';
import styles from './mapInfoPointPage.module.sass'
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {useParams} from "react-router-dom";
import {fetchInfoTC, fetchPointsListTC, setDateAC, setIntervalAC, setPointAC} from "../../../redux/pointReducer";
import {RequestPointType, StoreType} from "../../../common/types";
import {
    selectEndDatePoint,
    selectInfoPoint, selectInterval,
    selectListPoint, selectPoint,
    selectStartDatePoint, selectStatusPoint,
    selectStore
} from "../../../common/selectors";
import {fetchStoreTC} from "../../../redux/mapReducer";
import {DatePicker, Select} from "antd";
import {ContentBlock} from "./contentBlock";
import {Spinner} from "../../../common/spin";

import dateIcon from '../../../assets/datePickerIcon.svg'
import closeIcon from '../../../assets/close.svg'

import dayjs, {Dayjs} from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import 'dayjs/locale/zh-cn';

import updateLocale from 'dayjs/plugin/updateLocale';
import locale from "antd/es/date-picker/locale/ru_RU";
import {newRegularForMapNames} from "../../../common/optionsForMaps";
import {RangePickerProps} from "antd/es/date-picker";


dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(updateLocale);
dayjs.updateLocale('zh-cn', {
    weekStart: 1,
});

export const MapInfoPointPage = () => {

    const params = useParams()

    const dispatch = useAppDispatch()

    const info = useAppSelector(selectInfoPoint)
    const stores = useAppSelector(selectStore)
    const list = useAppSelector(selectListPoint)

    const startDate = useAppSelector(selectStartDatePoint)
    const endDate = useAppSelector(selectEndDatePoint)
    const point = useAppSelector(selectPoint)
    const interval = useAppSelector(selectInterval)
    const status = useAppSelector(selectStatusPoint)

    const {RangePicker} = DatePicker

    const req: RequestPointType = {
        intervalType: interval,
        startDate: startDate,
        endDate: endDate
    }

    useEffect(() => {
        if (params.id) {
            dispatch(fetchStoreTC({}))
            dispatch(fetchPointsListTC())
            dispatch(fetchInfoTC({id: point ? point : params.id, data: req}))
        }
    }, [startDate, endDate, point, interval]);
    const listIds = list.map((el) => el.depotId)

    const filteredStores = stores.filter((el) => listIds.includes(el.title))

    const regData = newRegularForMapNames(filteredStores)

    const filteredRegData:  StoreType[] = Object.values(regData.reduce((acc: any, n: any) => (   // проверка на одинаковые id (title)
        acc[n.title] = n.checked ? n : (acc[n.title] || n), acc
    ), {}));

    const store = stores.find((el) => point ? el.title === point : el.title === params.id)

    const options = filteredRegData.map((el) => ({label: `${el.title} ${el.adr}`, value: el.title}))

    const optionsInterval =  [
        {label: 'по дням', value: 0},
        {label: 'по неделям', value: 1},
        {label: 'по месяцам', value: 2, disabled: false},
    ]

    const onChangeDateHandler = (value: null | (Dayjs | null)[], dateString: string[]) => {

        dispatch(setDateAC(dateString))
    }

    const onChangePointHandler = (value: string) => {
        dispatch(setPointAC(value))
    }

    const onChangeIntervalHandler = (value: number) => {
        dispatch(setIntervalAC(value))
    }

    const disabledDate = (current: dayjs.Dayjs) => {
        return current && (current.isBefore(dayjs("2024-10-01"), "day") || current.isAfter(dayjs(), "day"));
    };

    return (
        <div className={styles.main}>
            <div className={styles.titleBlock}>
                <div className={styles.title}>{(store?.adr)}</div>
                <div className={styles.divider}></div>
            </div>
            <div className={styles.settingsBlock}>
                <RangePicker
                    onChange={onChangeDateHandler}
                    defaultValue={[dayjs(startDate), dayjs(endDate)]}
                    locale={locale}
                    suffixIcon={<img src={dateIcon} alt={'select'}/>}
                    disabledDate={disabledDate}
                    allowClear={{
                        clearIcon: <img className={styles.closeIcon} src={closeIcon} alt={'clear'}/>
                    }}
                />
                <Select
                    style={{width: '50%'}}
                    placeholder={'лавка'}
                    options={options}
                    onChange={onChangePointHandler}
                    defaultValue={params.id}
                />
                <Select
                    placeholder={'период'}
                    options={optionsInterval}
                    onChange={onChangeIntervalHandler}
                    defaultValue={interval}
                    style={{width: '15%'}}
                />
            </div>
            {info ? (status === 'loading' ? <div style={{height: 700}}><Spinner/></div> : <ContentBlock data={info}/>) :
                <div style={{height: 700}}><Spinner/></div>}
        </div>
    );
};


