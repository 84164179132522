import React, {useRef} from 'react';
import {Charts} from "./charts";
import {TableMarketplaces} from "./tableMarketplaces";
import {Button} from "antd";

import styles from './content.module.sass'
import {ExportCSV} from "../../utils/exportToCSV";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {
    choiceOfPlatform,
    loadingTables,
    selectCategory,
    selectChart, selectHidden,
    selectSwitchCategories, selectTableChartCategories,
    setTable,
    visiblePDF, visTable
} from "../../common/selectors";
import {ChartsButtons} from "./charts/chartsButtons";
import {Spinner} from "../../common/spin";
import {visiblePDFAC} from "../../redux/optionsReducer";
import {TimeLineChart} from "./timeLineChart";
import {useReactToPrint} from "react-to-print";
import {TableSupermarkets} from "./tableSupermarkets";
import {SettingForTable} from "../options/settingForTable";
import {TimeLineChart1} from "./timeLineChart/timeLineChart1";
import cn from 'classnames'
import {NewsComponent} from "../newsComponent";


export const Content = () => {

    const platform = useAppSelector(choiceOfPlatform)
    const chart = useAppSelector(selectChart)
    const category = useAppSelector(selectCategory)
    const loadingForTables = useAppSelector(loadingTables)
    const categories = useAppSelector(selectSwitchCategories)
    const table = useAppSelector(setTable)
    const visible = useAppSelector(visiblePDF)
    const hidden = useAppSelector(selectHidden)
    const visibleTable = useAppSelector(visTable)
    const tableChartsCategories = useAppSelector(selectTableChartCategories)

    const dispatch = useAppDispatch()



    /*const { toPDF, targetRef } = usePDF({
        filename: "test.pdf",
        page: { margin: Margin.MEDIUM }
    });*/

    /*const onChange = (checked: boolean) => {
        dispatch(setTableAC(checked))
    };*/

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            dispatch(visiblePDFAC(true));
        }

    });

    const onMouseDown = () => dispatch(visiblePDFAC(false))


    return (
        <div className={styles.main} ref={componentRef} >
            <div className={styles.content}>
                <div className={cn(styles.charts, hidden&&styles.hiddenChart)}><Charts/></div>
                <div style={{opacity: visible?1:0}}><ChartsButtons/></div>
                {platform==='stocks'&&<SettingForTable buttonName={category === 'marketplaces' ? 'по маркетплейсам' : 'по супермаркетам'}/>}
                {!table&&window.innerWidth > 1000 && visibleTable && !tableChartsCategories && (loadingForTables==='loading' && !categories ? <Spinner/> : <TimeLineChart1/>)}
                {table && visibleTable && (loadingForTables==='loading' ? <Spinner/> : !categories && (category === 'marketplaces' ? <TableMarketplaces/> : (category === 'supermarkets'&& <TableSupermarkets/>)))}
            </div>
            <div style={{
                display: 'none',
                justifyContent: "flex-end",
                marginTop: 20,
                marginBottom: 40,
                opacity: visible ? 1 : 0
            }}>
                <Button onClick={handlePrint} onMouseUp={onMouseDown}
                        style={{borderRadius: 5, backgroundColor: '#0A6C87', color: 'white'}}>Печать PDF</Button>
                <ExportCSV/>
            </div>
        </div>
    );
};

