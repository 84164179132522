import React, {useEffect, useState} from 'react';

import styles from './main.module.sass'
import {fetchData} from "../../redux/chartsReducer";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {
    bannerStatus, choiceOfPlatform,
    selectCategory,
    selectChart,
    selectCountProd,
    selectMarketPlaces,
    selectSOV,
    selectSuperMarkets,
    selectTableChartCategories, selectTableContent, selectTableContentForSpm,
    selectTime,
    setError
} from "../../common/selectors";
import {defData} from "../../common/optionsForSwitches";
import {changeDataFactorAC, filterAC} from "../../redux/optionsReducer";
import {fetchDataTable, fetchDataTableSpm} from "../../redux/tableReducer";
import {isLoggedInAC} from "../../redux/authReducer";
import {Outlet, useNavigate} from "react-router-dom";
import {AUTH} from "../../common/constants";
import {sessionEndDate, sessionStartDate} from "../../common/optionsForDate";
import {Header} from "../../components/header";

export const Main = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const chart = useAppSelector(selectChart)
    const time = useAppSelector(selectTime)
    const category = useAppSelector(selectCategory)
    const tableCharts = useAppSelector(selectTableChartCategories)
    const marketPlacesNames = useAppSelector(selectMarketPlaces)
    const superMarketsNames = useAppSelector(selectSuperMarkets)
    const countProd = useAppSelector(selectCountProd)
    const SOV = useAppSelector(selectSOV)
    const error = useAppSelector(setError)
    const bannerPost = useAppSelector(bannerStatus)


    let filter = defData
    category === 'marketplaces' ? filter = marketPlacesNames.concat(SOV) : filter = superMarketsNames.concat(SOV)

   const [bannerReload, setBannerReload] = useState<boolean>(false)

    useEffect(() => {
        if (error === 401) {
            navigate(AUTH)
            dispatch(isLoggedInAC(true))
        }
        if (chart === 'Рынок, руб' || chart === 'Рынок, темпы') {
            if ( category === 'marketplaces') {
                dispatch(fetchDataTable({from_date: time[0], to_date: time[1], name_sale: '', spm:''}))
            }
            if ( category === 'supermarkets') {
                dispatch(fetchDataTableSpm({from_date: time[0], to_date: time[1]}))
            }
        }
        dispatch(changeDataFactorAC(false))
        dispatch(filterAC(filter))
        dispatch(fetchData({from_date: time[0], to_date:time[1], category: category, gin_name: chart}))
    },[dispatch,
        time,
        category,
        chart,
        marketPlacesNames,
        superMarketsNames,
        SOV,
        error,
        sessionStartDate,
        sessionEndDate,
        navigate,
        countProd,
        tableCharts,
        //bannerPost
    ])

    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <Header/>
                <Outlet/>
            </div>
        </div>
    );
};

