import {defValueForMarketPlaces} from "./optionsForCheckBoxes";
import {lightColor} from "./optionsForColors";

export const SOVForMarketPlaces = [
    'Ya.M SOV',
    'OZON SOV',
    'SMM SOV',
    'WB SOV',
    //'MM SOV',
]

export const SOVForSuperMarkets = [
    'X5 SOV',
    'Купер SOV',
    'Самокат SOV',
    'Я.Лавка SOV',
    'Магнит SOV',
    'Вкусвилл SOV',
]

export const defData = defValueForMarketPlaces.concat(SOVForMarketPlaces)

export const colorsMpsSOV = [
    {name: 'Ya.M SOV', color: lightColor},
    {name: 'OZON SOV', color: '#0059FF'},
    {name: 'SMM SOV', color: '#24b23e'},
    {name: 'WB SOV', color: '#C811aa'},
   // {name: 'MM SOV', color: '#24b23e'},
]

export const colorsSpmSOV = [
    {name: 'X5 SOV', color: '#ff7c80'},
    {name: 'Купер SOV', color: '#24b23e'},
    {name: 'Самокат SOV', color: '#ff335f'},
    {name: 'Я.Лавка SOV', color: '#00bfdf'},
    {name: 'Магнит SOV', color: '#e30613'},
    {name: 'Вкусвилл SOV', color: '#179c49'},
]