import React from 'react';
import styles from './chartsPage.module.sass'
import {Options} from "../../components/options";
import {Content} from "../../components/content";
import {NewsComponent} from "../../components/newsComponent";
import {NewsOptions} from "../../components/newsComponent/newsOptions";
import {useAppSelector} from "../../redux/store";
import {choiceOfPlatform, visNews} from "../../common/selectors";

export const ChartsPage = () => {
    const news = useAppSelector(visNews)
    const platform = useAppSelector(choiceOfPlatform)

    return (
        <>
            <div className={styles.section} id={'content'}>
                <Options/>
                <Content/>
            </div>
            <div id={'table'}></div>
            {news && platform === 'stocks' && <div className={styles.section}>
                <NewsOptions/>
                <NewsComponent/>
            </div>}
        </>

    );
};

