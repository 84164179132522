import React, {memo} from 'react';

import styles from './options.module.sass'
import {DatePickerComponent} from "./datePicker";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {
    choiceOfPlatform,
    loadingCharts,
    selectCategory,
    selectChart,
    selectCitiesPriceColumnCityChart,
    selectHidden,
    selectSpmPriceColumnCityChart,
    selectSpmPriceColumnForAllChart, selectSpmPriceForLineChart,
    selectTableChartCategories, selectTimeForCategories,
    selectTimePriceColumnCityChart,
    selectTimePriceColumnForAllChart,
    selectTimePriceLineChart
} from "../../common/selectors";
import {SuperMarkets} from "./superMarkets";
import {MarketPlaces} from "./marketPlaces";
import {Switches} from "./switches";
import {Buttons} from "./buttons";
import {Spinner} from "../../common/spin";
import {DatePickerPrice} from "./priceOptions/datePickerPrice";
import {CheckboxesGroupPrice} from "./priceOptions/checkboxesGroupPrice";
import {Button, Collapse, CollapseProps} from "antd";
import {citiesForPrice, citiesToSpm, spmForPrice} from "../../common/optionsForCheckBoxes";
import {
    changeCitiesPriceColumnForCityChartAC,
    changeColumnCityChartAC,
    changePriceColumnForAllChartAC,
    changePriceLineChartAC,
    changeSpmPriceColumnForAllChartAC,
    changeSpmPriceColumnForCityChartAC,
    collapseAC, selectSpmPriceForLineChartAC
} from "../../redux/priceReducer";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {CheckboxesPrice} from "./priceOptions/checkboxesPrice";
import {changeTimeValue, zoomAC} from "../../redux/optionsReducer";
import {
    defaultAllChartTime,
    defaultCityChartTime,
    defaultLineChartTime,
    defaultTime,
    getTimeCatToTime
} from "../../common/optionsForDate";
import cn from "classnames";
import {Categories} from "./categories";


export const Options = memo(() => {

    const dispatch = useAppDispatch()

    const category = useAppSelector(selectCategory)
    const chart = useAppSelector(selectChart)
    const loading = useAppSelector(loadingCharts)
    const platform = useAppSelector(choiceOfPlatform)
    const tableCharts = useAppSelector(selectTableChartCategories)
    const timeCat = useAppSelector(selectTimeForCategories)  // дата для категорий (1 неделя)

    const priceLineChartTime = useAppSelector(selectTimePriceLineChart)
    const priceLineChartSpm = useAppSelector(selectSpmPriceForLineChart)


    const priceColumnForAllChartTime = useAppSelector(selectTimePriceColumnForAllChart)
    const priceColumnCityChartTime = useAppSelector(selectTimePriceColumnCityChart)

    const priceColumnForAllChartSpm = useAppSelector(selectSpmPriceColumnForAllChart)

    const priceColumnForCityChartCity = useAppSelector(selectCitiesPriceColumnCityChart)
    const priceColumnForCityChartSpm = useAppSelector(selectSpmPriceColumnCityChart)
    const hidden = useAppSelector(selectHidden)

    const lineChartTimeHandler = (date: [string, string] | string) => {
        dispatch(changePriceLineChartAC(date))
    }

    const checkBoxForLineChartHandler = (spm: CheckboxValueType[]) => {
        dispatch(selectSpmPriceForLineChartAC(spm))
    }

    const columnForAllChartTimeHandler = (date: [string, string] | string) => {
        dispatch(changePriceColumnForAllChartAC(date))
    }
    const columnCityChartTimeHandler = (date: [string, string] | string) => {
        dispatch(changeColumnCityChartAC(date))
    }


    const checkBoxForAllChartHandler = (spm: CheckboxValueType[]) => {
        dispatch(changeSpmPriceColumnForAllChartAC(spm))
    }
    const checkBoxSpmForCityChartHandler = (spm: CheckboxValueType[]) => {
        dispatch(changeSpmPriceColumnForCityChartAC(spm))
        const possiblesCities = citiesToSpm.filter((el:any) => el.spm === spm)[0].cities
        const filteredCities = priceColumnForCityChartCity.filter((el:any) => possiblesCities.includes(el))
        dispatch(changeCitiesPriceColumnForCityChartAC(filteredCities))
    }
    const checkBoxCitiesForCityChartHandler = (city: CheckboxValueType[]) => {
        city.sort()
        dispatch(changeCitiesPriceColumnForCityChartAC(city))
    }

    const onClickHandlerBackLine = () => {
        dispatch(changePriceLineChartAC(defaultLineChartTime))
    }

    const onClickHandlerBackAll = () => {
        dispatch(changePriceColumnForAllChartAC(defaultAllChartTime))
    }

    const onClickHandlerBackCity = () => {
        dispatch(changeColumnCityChartAC(defaultCityChartTime))
    }


    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Показатели общие',
            children: <div style={{marginTop: 15, height: 340}}>
                <DatePickerPrice defaultTime={priceLineChartTime}
                                 callBack={lineChartTimeHandler}
                                 sessionLabel={['startLineChartTime', 'endLineChartTime']}/>
                <CheckboxesGroupPrice list={spmForPrice}
                                      type={'spm'}
                                      defaultValue={priceLineChartSpm}
                                      callBack={checkBoxForLineChartHandler}
                                      value={priceLineChartSpm}
                />
                <Button type={'link'} onClick={onClickHandlerBackLine}>Стандартный период</Button>

            </div>,
        },
        {
            key: '2',
            label: 'Показатели по супермаркетам',
            children: <div style={{marginTop: 15, height: 360}}>
                <DatePickerPrice defaultTime={priceColumnForAllChartTime}
                                         callBack={columnForAllChartTimeHandler}
                                         sessionLabel={['startAllChartTime', 'endAllChartTime']}/>
                <CheckboxesGroupPrice list={spmForPrice}
                                      type={'spm'}
                                      defaultValue={priceColumnForAllChartSpm}
                                      callBack={checkBoxForAllChartHandler}
                                      value={priceColumnForAllChartSpm}
                />
                <Button type={'link'} onClick={onClickHandlerBackAll}>Стандартный период</Button>
            </div>,
        },
        {
            key: '3',
            label: 'Показатели по городам',
            children: <div style={{marginTop: 15}}><DatePickerPrice defaultTime={priceColumnCityChartTime}
                                         callBack={columnCityChartTimeHandler}
                                         sessionLabel={['startCityChartTime', 'endCityChartTime']}/>
                <div style={{display: "flex"}}>
                    <CheckboxesPrice list={spmForPrice}
                                     value={priceColumnForCityChartSpm}
                                     callBack={checkBoxSpmForCityChartHandler}
                                     cities={priceColumnForCityChartCity}
                    />
                    <CheckboxesGroupPrice list={citiesForPrice}
                                          type={'city'}
                                          defaultValue={priceColumnForCityChartCity}
                                          callBack={checkBoxCitiesForCityChartHandler}
                                          spm={priceColumnForCityChartSpm}
                                          value={priceColumnForCityChartCity}
                    />

                </div>
                <Button type={'link'} onClick={onClickHandlerBackCity}>Стандартный период</Button>
            </div>,
        },
    ];
    
    const onChange = (key: string | string[]) => {
        dispatch(collapseAC(key))
    }

    const onClickHandler = () => {
        sessionStorage.clear()
        if (platform === 'stocks') {
            dispatch(changeTimeValue(defaultTime))
        }
        if (platform === 'categories') {
            dispatch(changeTimeValue(getTimeCatToTime(timeCat)))
        }
        dispatch(zoomAC(defaultTime))
    }

    const getCheckboxBlock = () => {
        if (platform === 'stocks') {
            if (chart === 'Каталог' || chart === 'Каталог1') {
                return <div className={styles.boxes}>
                    <MarketPlaces/>
                    <Categories/>
                </div>
            }
            if (chart !== 'Каталог') {
                return category === 'marketplaces' ? <MarketPlaces/> : <SuperMarkets/>
            }
        }
        if (platform === 'categories') {
            if (chart === 'Категории') {
                return <MarketPlaces/>
            }
            if (chart === 'Данные 1 уровня' && tableCharts && tableCharts !== 'Динамика количества товаров в каталоге') {
                return <div className={styles.boxes}>
                    <MarketPlaces/>
                    <Categories/>
                </div>
            }
            if (chart === 'Данные 1 уровня' && tableCharts && tableCharts === 'Динамика количества товаров в каталоге') {
                return <MarketPlaces/>
            }
        }
    }

    const checkboxBlock = getCheckboxBlock()

    return (
        <div className={cn(styles.main, hidden && styles.hiddenChart)}>
            {platform !== 'price' && <div>
                <Buttons place={'stock'}/>
                <div className={styles.checkboxBlock}>
                    <div className={styles.items} style={{height: platform === 'categories' ? 500 : 400}}>
                        {loading === 'loading' ? <Spinner/> : checkboxBlock}
                    </div>
                </div>
            </div>}
            {platform !== 'price' ?
                <div className={styles.periodBlock}>
                    <div className={styles.title}>
                        Период
                        <Button type={'link'} onClick={onClickHandler}>Стандартный период</Button>
                    </div>
                    <DatePickerComponent/>
                    <Switches/>
                </div> :
                <div className={styles.price}>
                    <Buttons place={'price'}/>
                    <div className={styles.cards}>
                        <Collapse items={items}
                                  defaultActiveKey={['1', '2', '3']}
                                  bordered={false}
                                  style={{marginTop: '20px', backgroundColor: '#202020'}}
                                  onChange={onChange}/>
                    </div>
                </div>
            }
        </div>
    );
});

