import axios from "axios";
import {HOST_GEO} from "../common/constants";
import * as https from "node:https";

const instance = axios.create({
    baseURL: /*'http://79.174.92.234:8156/b-geo'*/  HOST_GEO,

})

instance.interceptors.request.use(
    request => {

        request.headers.Authorization = `JWT ${localStorage.getItem('jwt')}`
        request.headers['Content-Type'] = 'multipart/form-data'

        return request
    },
    error => {
        console.log(error)
    }
);

export const mapApi = {
    getPolygons(){
        return instance.get('/polygons/')
    },
    getBuildings(lavka_title: string){
        return instance.get(`/building/?lavka_id=${lavka_title}`)
    },
    getStore( params: {coords_sw?: number[], coords_ne?: number[]} = {}){
        if (params.coords_sw) {
            return instance.get(`/lavka?coords_sw=${params?.coords_sw}&coords_ne=${params?.coords_ne}`)
        }
        return instance.get(`/lavka?coords_sw=&coords_ne=`)
    },

}