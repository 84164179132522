import React from 'react';
import {ContentBlockPropsType} from "../contentBlock";
import dayjs from "dayjs";
import {Table, TableColumnsType} from "antd";
import {InfoPoint} from "../../../../../common/types";
import {formatGrade} from "../../../../../common/optionsForCategories/table";

type PointTablePropsType = {
    data: InfoPoint | null,
    interval: 0 | 1 | 2

}


export const PointTable = ({data, interval}: PointTablePropsType) => {


    const columns: any = data?.sales.map((el, index) => (
        {
            key: index,
            title: interval ?
                `${dayjs(el.startDate).format('DD/MM')} - ${index === data?.sales.length-1 ? dayjs(el.endDate).format('DD/MM') : dayjs((dayjs(el.endDate).valueOf() - 15000000)).format('DD/MM')}` :
                dayjs(el.startDate).format('DD/MM'),      // если график по дням, формат DD/MM, если по неделям, то дата начала - дата конца, где от даты конца отнято произвольное чило 15000000, чтобы она вписалась в неделю.(кроме последней даты)
            dataIndex: el.startDate,
            align: 'center'
        }
    ))

    columns.unshift({
        key: -1,
        title: 'Показатель',
        dataIndex: 'name',
        align: 'center',
        fixed: 'left'
    })

    let dataTable = [
        {
            key: 'count',
            name: 'Продано товаров'
        },
        {
            key: 'summ',
            name: 'Объем продаж'
        }
    ]

    data?.sales.forEach((el) => (
        dataTable[0] = {...dataTable[0], [el.startDate]: formatGrade(el.salesCount)},
        dataTable[1] = {...dataTable[1], [el.startDate]: formatGrade(el.salesSum)}
    ))

    return (
        <div>
            <Table
                columns={columns}
                dataSource={dataTable}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </div>
    );
};

