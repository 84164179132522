import {StoreType} from "./types";

export const mosc = [[55.52512140845406, 37.193031616210945], [55.97050381526683, 37.94834167480469]]
export const spb = [[59.740097561220196, 29.942654780273426], [60.135976970762215, 30.685605219726558]]

export const citiesMapData = [
    {name: 'Москва', href: 'moscow', center: [55.75, 37.57], zoom: 11, bounds: mosc},
    {name: 'Санкт-Петербург', href: 'spb', center: [59.93863, 30.31413], zoom: 11, bounds: spb}
]



const abbreviationMap = {
    'улица': 'ул.',
    'проспект': 'пр.',
    'просп.': 'пр.',
    'пр-кт.': 'пр.',
    'шоссе': 'ш.',
    'бульвар': 'б-р',
    'проезд': 'пр-д',
    'набережная': 'наб.',
    'переулок': 'пер.',
    'площадь': 'пл.',
    'дорога': 'дор.',
    'корпус': 'к.',
    'корп': 'к.',
    'строение': 'стр.',
    'строен.': 'стр.',
    'литера': 'лит.'
};

export const newRegularForMapNames = (storeData: StoreType[]) => {
    return storeData.map((el) => (
        {...el, adr:  el.adr
                .replace('г Москва,', '')
                .replace('Московская обл,', '')
                .replace('Московская область,', '')
                .replace('г Санкт-Петербург,', '')
                .replace('Ленинградская обл,', '')
                .replace('Всеволожский р-н,', '')
                .replace(' д ', ' ')
                .replace(' к ', ' к.')
                .replace(' стр ', ' стр.')
                .replace('литера', ' лит.')
                .replace('ул ', 'ул. ')


                .replace('проезд ', 'пр-д ')
                .replace('пр-кт ', 'пр. ')
                .replace('ш ', 'ш. ')
                .replace('наб ', 'наб. ')
                .replace('пер ', 'пер. ')
                .replace('пл ', 'пл. ')
                .replace('г ', 'г. ')
                .trim()
        }
    ))
}


export const regularForMapNames = (storeData: StoreType[]) => {
        return  storeData.map((el) => (
           {...el, adr:  el.adr
                .replace(' , ', '')
                .replace(/^\d{6}\s*,?\s*/, '')
                .replace(/^\d{5}\s*,?\s*/, '')
                .replace(/г\.?\s*Москва,?/gi, '')
                .replace(/г\.?\s*Московский,?/gi, '')
                .replace(/город\s*Москва,?/gi, '')
                .replace(/Москва г,,?/gi, '')
                .replace(/Москва,,?/gi, '')
                .replace(/г\.?\s*Санкт-Петербург,,?/gi, '')
                //.replace(/г\.?\s*Санкт-Петербург,,?/gi, '')
                .replace(/город\s*Санкт-Петербург,,?/gi, '')
                .replace(/Санкт-Петербург г,,?/gi, '')
                .replace(/Санкт-Петербург,,?/gi, '')
                 .replace(/г\.?\s*Санкт-Петербург?/gi, '')
                .replace('дом', '')
                .replace('д.', '')
                .replace('ул,', '')
                .replace('улица', '')
                .replace('6с2', '6 стр.2')
                .replace('5к1', '5 к.1')
                .replace('А,', 'А')
                .replace('Московская область,', '')
                .replace('Московская обл.,', '')
                .replace('Московская обл,', '')
                .replace('Россия,', '')
                .replace('Ленинградская область,', '')
                .replace('Ленинградская обл.,', '')
                .replace('Тосненский район,', '')
                .replace('Всеволожский район,', '')
                .replace('Всеволожский муниципальный район,', '')
                .replace('Фёдора Абрамова,', 'ул. Фёдора Абрамова')
                //.replace('город Льва Толстого,', 'ул. Льва Толстого')
                .replace(/\*|%|#|&|№|\$/g, '')



                .replace(/улица|ул\./gi, abbreviationMap['улица'])
                .replace(/проспект|пр\./gi, abbreviationMap['проспект'])
                .replace(/просп.|пр\./gi, abbreviationMap['просп.'])
                .replace(/пр-кт.|пр\./gi, abbreviationMap['пр-кт.'])
                .replace(/шоссе|ш\./gi, abbreviationMap['шоссе'])
                .replace(/бульвар|б-р/gi, abbreviationMap['бульвар'])
                .replace(/проезд|пр-д/gi, abbreviationMap['проезд'])
                .replace(/набережная|наб\./gi, abbreviationMap['набережная'])
                .replace(/переулок|пер\./gi, abbreviationMap['переулок'])
                .replace(/площадь|пл\./gi, abbreviationMap['площадь'])
                .replace(/дорога|дор\./gi, abbreviationMap['дорога'])
                .replace(/корпус |к.\./gi, abbreviationMap['корпус'])
                .replace(/корп. |к.\./gi, abbreviationMap['корп'])
                .replace(/строение|стр\./gi, abbreviationMap['строение'])
                .replace(/строен.|стр\./gi, abbreviationMap['строен.'])
                .replace(/литера|лит\./gi, abbreviationMap['литера'])
                .replace(',', '')
                .replace(' ,', '')
                .replace(/(\d),/g, '$1') // убирает зпт после цифры
                .replace(/(\d),/g, '$1') // убирает зпт после цифры
                .replace(/\s+/g, ' ') //убирает  2 пробела
                .replace(/(к\.|стр\.)\s+/g, '$1') //убрать пробелы после 'к.' и 'стр.'
                //.replace(/\s(к\.|стр\.)+/g, '$1') //убрать пробелы после 'к.' и 'стр.'
                .replace(/(?<!^)(\s)(\d)/g, ', $2') //добавить запятую перед цифрой через пробел, если ее нет и если она не вначале строки
                .replace(',,', ',') //убирает  2 запятых
                .replace('город Реутов Лесная,', 'Реутов ул. Лесная') //убирает  2 запятых
                .trim()}

       )).map((el) => {
           if (
               !el.adr.includes('ул.') &&
               !el.adr.includes('пр.') &&
               !el.adr.includes('ш.') &&
               !el.adr.includes('б-р') &&
               !el.adr.includes('пр-д') &&
               !el.adr.includes('наб.') &&
               !el.adr.includes('пер.') &&
               !el.adr.includes('пл.') &&
               !el.adr.includes('дор.') &&
               !el.adr.includes('аллея') &&
               !el.adr.includes('Андреевка') &&
               !el.adr.includes('Зеленоград') &&
               !el.adr.includes('Парголово') &&
               !el.adr.includes('Люберцы') &&
               !el.adr.includes('Красногорск')
           ) {
              return {...el, adr: 'ул. ' + el.adr}
            }
           return el
       })

    }

