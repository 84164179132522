import dayjs from "dayjs";

export const formatWeekToISODate = (arg: string) => {
    const [weekVar, year] = arg.split('_').map(Number)

    const startOfWeek = dayjs().year(year).isoWeek(weekVar).startOf('isoWeek')
    const endOfWeek = dayjs().year(year).isoWeek(weekVar).endOf('isoWeek')

    const formattedStart = startOfWeek.format('DD.MM.YYYY')
    const formattedEnd = endOfWeek.format('DD.MM.YYYY')

    return `${formattedStart} - ${formattedEnd}`
}